import axios from '@libs/axios'
import { NewsListParams, INewsList } from './types/News'
const prefix = '/admin'
export const fetchNews = async (params: NewsListParams) => {
  const { data } = await axios.get<INewsList>(`${prefix}/news`, {
    params
  })
  return data
}
export const createNews = async (formData: FormData) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  }
  const { data } = await axios.post(`${prefix}/news`, formData, { headers })
  if (data?.errors) {
    throw new Error(data?.message)
  }
  return data
}

export const deleteNews = async (id: number) => {
  const { data } = await axios.delete(`${prefix}/news/${id}`)
  if (data?.errors) {
    throw new Error(data?.message)
  }
  return data
}

export const fetchNewsDetail = async (id: number) => {
  const { data } = await axios.get<any>(`${prefix}/news/${id}`)
  if (data?.errors) {
    throw new Error(data?.message)
  }
  return data
}
export const updateNews = async (id: number, formData: FormData) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  }
  const { data } = await axios.put(`${prefix}/news/${id}`, formData, { headers })
  if (data?.errors) {
    throw new Error(data?.message)
  }
  return data
}

export const uploadImageBody = async (formData: FormData) => {
  const { data } = await axios.post(`${prefix}/upload-file`, formData)
  if (data?.errors) {
    throw new Error(data?.message)
  }
  return data
}
