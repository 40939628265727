import { z } from 'zod'
const ErrorMessageKeys = {
  MAX: 'MAX',
  MIN_ARRAYLENGTH: 'MIN_ARRAYLENGTH',
  MIN_VALUE: 'MIN_VALUE',
  MAX_VALUE: 'MAX_VALUE',
  REQUIRED: 'REQUIRED'
} as const

const errorMessage = (key: string, paramValue: any = ''): string => {
  return `ERROR_${key}${paramValue ? '_' + paramValue : ''}`
}

const message = {
  max: (value: number) => errorMessage(ErrorMessageKeys.MAX, value),
  required: () => 'ERROR_REQUIRED'
}

const CreateNotificationSchema = z.object({
  title: z.object({
    en: z.string().min(1, message.required()).max(25, message.max(25)),
    ja: z.string().min(1, message.required()).max(25, message.max(25)),
  }),
  description: z.object({
    en: z.string().min(1, message.required()).max(255, message.max(255)),
    ja: z.string().min(1, message.required()).max(255, message.max(255)),
  }),
  category: z.number(),
  status: z.number(),
  start_announcemented_at: z.string(),
  end_announcemented_at: z.string()
})

const EditNotificationSchema = z.object({
  title: z.object({    
    en: z.string().min(1, message.required()).max(25, message.max(25)),
    ja: z.string().min(1, message.required()).max(25, message.max(25))}),
  description: 
  z.object({
    en: z.string().min(1, message.required()).max(255, message.max(255)),
    ja: z.string().min(1, message.required()).max(255, message.max(255))
  }),
  category: z.number(),
  status: z.number(),
  start_announcemented_at: z.string(),
  end_announcemented_at: z.string()
})

type TCreateNotification = z.TypeOf<typeof CreateNotificationSchema>
type TUpdateNotification = z.TypeOf<typeof EditNotificationSchema>

export type { TCreateNotification, TUpdateNotification }
export { CreateNotificationSchema, EditNotificationSchema }
