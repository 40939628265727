import classNames from 'classnames'
import React, { HTMLProps } from 'react'
import styled, { keyframes } from 'styled-components'

type LoadingComponentProps = {
  className?: HTMLProps<HTMLElement>['className']
}
const overlayAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${overlayAnimation} 0.3s ease-in-out;
`

const LoadingComponent = (props: LoadingComponentProps) => {
  return (
    <Overlay className={classNames(props.className)}>
      <div className='spinner-border text-primary' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </Overlay>
  )
}

export default LoadingComponent
