import * as z from 'zod'

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB in bytes

const message = {
  max: (value: number, name: string) => `VALID_${name.toUpperCase()}_MAX_LENGTH_${value}`,
  fileSize: (maxSize: number) =>
    `Uploaded file exceeded the maximum size of ${maxSize / (1024 * 1024)}MB. Reduce your file and try again`
}
const CreateNewsSchema = z.object({
  title: z
    .string({ required_error: 'TITLE_REQUIRED' })
    .min(1, { message: 'TITLE_REQUIRED' })
    .max(255, message.max(255, 'title')),
  body: z
    .string({ required_error: 'BODY_REQUIRED' })
    .min(1, { message: 'BODY_REQUIRED' }),
  file: z
    .instanceof(File, { message: 'IMAGE_REQUIRED' })
    .refine((file) => file.size <= MAX_FILE_SIZE, { message: 'VALID_IMAGE' })
})
const UpdateNewsSchema = z.object({
  title: z
    .string({ required_error: 'TITLE_REQUIRED' })
    .min(1, { message: 'TITLE_REQUIRED' })
    .max(255, message.max(255, 'title'))
    .optional(),
  body: z
    .string({ required_error: 'BODY_REQUIRED' })
    .min(1, { message: 'BODY_REQUIRED' }),
  file: z
    .instanceof(File, { message: 'IMAGE_REQUIRED' })
    .refine((file) => file.size <= MAX_FILE_SIZE, { message: 'VALID_IMAGE' })
    .optional()
})

type TCreateNews = z.TypeOf<typeof CreateNewsSchema>
type TUpdateNews = z.TypeOf<typeof UpdateNewsSchema>

export type { TCreateNews, TUpdateNews }
export { CreateNewsSchema, UpdateNewsSchema }
