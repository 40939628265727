import { objToOption } from '@utils/enumToOption'
import { reverseEnum } from '@utils/reverseEnum'

export const EnumCategory: { [key: string]: string } = {
  1: 'BUG',
  2: 'MAINTENANCE',
  3: 'EVENT',
  4: 'UPDATE'
}
export const EnumCategoryColor: { [key: string]: string } = {
  1: '#8B4EB1',
  2: '#DEA403',
  3: '#33D3D3',
  4: '#A7EB79',
  default: 'rgb(219 234 254)'
}
export const OptionStatus: { value: string | number | boolean; label: string }[] = [
  {
    label: 'DRAFT',
    value: 1
  },
  { label: 'PUBLISH', value: 2 }
]
export const OptionCategory = objToOption(reverseEnum(EnumCategory), 'number')
