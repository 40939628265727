import { FormCheck, FormCheckProps, Spinner } from 'react-bootstrap'
import styled, { css } from 'styled-components'

interface SwitchProps extends Omit<FormCheckProps, 'size'> {
  size?: 'sm' | 'md' | 'lg' | 'xl'
  className?: string
  loading?: boolean
}

const switchMixin = (res = 'sm') => {
  let index = '1rem'
  let mainVal = '1rem'

  if (res === 'md') {
    index = '2rem'
    mainVal = '1.5rem'
  } else if (res === 'lg') {
    index = '3rem'
    mainVal = '2rem'
  } else if (res === 'xl') {
    index = '4rem'
    mainVal = '2.5rem'
  }

  return css`
    /* margin-bottom: ${parseFloat(index) / 2}rem; */

    .form-check-input {
      height: ${mainVal};
      width: calc(${index} + 0.75rem);
      border-radius: ${parseFloat(mainVal) * 2};
    }
  `
}
const StyledSwitch = styled(FormCheck)<{ loading?: string }>`
  ${switchMixin()}

  .form-check-input {
    clear: left;
    cursor: pointer;
  }

  /* Sizes for different resolutions */
  &.form-switch-sm {
    ${switchMixin()}
  }

  &.form-switch-md {
    ${switchMixin('md')}
  }

  &.form-switch-lg {
    ${switchMixin('lg')}
  }

  &.form-switch-xl {
    ${switchMixin('xl')}
  }

  /* Loading state styles */
  ${({ loading }) =>
    loading === 'true' &&
    css`
      pointer-events: none; /* Disable clicks when loading */
      opacity: 0.6; /* Dim the button during loading */
    `}
`

const Switch = ({ className, size = 'sm', loading = false, ...rest }: SwitchProps) => {
  return (
    <StyledSwitch
      {...rest}
      type='switch'
      className={`form-check form-switch form-switch-${size} ${className}`}
      loading={loading.toString()}
    />
  )
}

export default Switch
