import Button from '@atoms/Button'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal } from 'react-bootstrap'

type ConfirmDialogProp = {
  title?: string
  okText?: string
  cancelText?: string
  children?: React.ReactElement | React.ReactNode
  open?: boolean
}

export type ConfirmDialogRef = {
  setOpen: (isOpen: boolean) => void
  setLoading: (isLoading: boolean) => void
  onOk?: (callback: () => void) => void
  onCancel?: (callback: () => void) => void
}

const ConfirmDialog = forwardRef<ConfirmDialogRef, ConfirmDialogProp>(
  ({ cancelText, children, okText, title, ...rest }: ConfirmDialogProp, ref) => {
    const [open, setIsOpen] = useState<boolean>(rest.open === true ? true : false)
    const [loading, setLoading] = useState<boolean>(false)
    const [onOk, setOnOk] = useState<() => void>(() => {})
    const [onCancel, setCancel] = useState<() => void>(() => {})

    useImperativeHandle(ref, () => ({
      setOpen(isOpen) {
        setIsOpen(isOpen)
      },
      setLoading(isLoading) {
        setLoading(isLoading)
      },
      onOk(callback: () => void) {
        setOnOk(() => callback)
      },
      onCancel(callback: () => void) {
        setCancel(() => callback)
      }
    }))

    return (
      <Modal show={open} backdrop='static' keyboard={false} centered>
        {title ? <Modal.Title>{title}</Modal.Title> : ''}
        <div className='p-4 relative'>
          <button
            type='button'
            className='text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:opacity-80 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
            data-modal-toggle='deleteModal'
            onClick={() => {
              if (onCancel) {
                onCancel()
              }
              setIsOpen(false)
            }}
          >
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
            <span className='sr-only'>Close modal</span>
          </button>
          <div>{children}</div>
          <div className='flex gap-2 justify-center'>
            <Button
              className='btn-danger'
              onClick={() => {
                if (onCancel) {
                  onCancel()
                }
                setIsOpen(false)
              }}
            >
              {cancelText ? cancelText : 'Cancel'}
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                if (onOk) {
                  onOk()
                }
              }}
            >
              {okText ? okText : 'Ok'}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
)

export default ConfirmDialog
