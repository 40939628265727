import axios from "@libs/axios";
import { LoginRequest, LoginResponse } from "./types/Auth";

const prefix = "/auth";
export const login = async (params: LoginRequest) => {
  const res = await axios.post<LoginResponse>(
    `${prefix}/login`,
    {
      public_key: params.publicKey,
    },
    {
      headers: {
        "X-Id-Token": params.xIdToken,
        "X-Wallet-Address": params.publicKey,
      },
    }
  );
  const data = res.data.data;
  return data;
};
