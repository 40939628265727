import { Card } from 'react-bootstrap'
import { useFieldArray, useFormContext } from 'react-hook-form'
import FormReward from './components/Form'
import { ContestRewards } from '@pages/Rewards/schema'
import { has } from 'lodash'
import React from 'react'
import { useRewardContext } from '@pages/Rewards/context'

type ClassRewardProps = {
  index: number
}
const ClassReward = ({ index }: ClassRewardProps) => {
  const { translation } = useRewardContext()
  const name = `rank_groups.[${index}].contest_rewards`

  const { control } = useFormContext()
  const { fields } = useFieldArray({
    control,
    name: name,
    keyName: '_id'
  })

  const renderRewardForm = (rewards: ContestRewards, index: number) => {
    return (
      <Card className='p-4 my-3'>
        <div className='flex items-center justify-between mb-3'>
          <div className='text-lg font-medium capitalize'>{translation('REWARD')}</div>
        </div>
        <FormReward index={index} name={name} />
      </Card>
    )
  }
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <React.Fragment key={item._id}>
            {!has(item, '_destroy') ? <div>{renderRewardForm(item as ContestRewards, index)}</div> : <></>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default ClassReward
