import Contests from './contests'
import Common from './common'
import Rewards from './rewards'
import Notification from './notification'
import News from './news'

export const JA: {
  [key: string]: any
} = {
  ...Common.ja,
  Contests: Contests.ja,
  Rewards: Rewards.ja,
  Notification: Notification.ja,
  News: News.ja
}
