// import Button from '@atoms/Button'
import { debounce } from 'lodash'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type SearchProps = {
  onSearch: (value: string) => void
}
type SearchParam = {
  key_search: string
}
const Search = ({ onSearch }: SearchProps) => {
  const { register } = useForm<SearchParam>({
    values: { key_search: '' },
    mode: 'onTouched',
    reValidateMode: 'onChange'
  })
  const { t } = useTranslation()
  const translation = (key: string, option?: any) => {
    const name = 'Contests'
    return t(`${name}.${key}`, option ?? {}) as string
  }
  return (
    <div>
      <Form
        onSubmit={(e) => {
          e.preventDefault()
        }}
        className='flex gap-3 w-[300px]'
      >
        <Form.Group controlId='form-name' className='w-full'>
          <Form.Control
            type='text'
            placeholder={translation('ENTER_TO_SEARCH', { nameToLowCase: translation('CONTEST').toLocaleLowerCase() })}
            {...(register('key_search'),
            {
              onChange: debounce((e) => {
                onSearch(e?.target?.value)
              }, 500)
            })}
          />
        </Form.Group>
      </Form>
    </div>
  )
}

export default Search
