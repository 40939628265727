import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { uploadImageBody } from '@store/news';

const CKEditorComponent = ({watch, setValue, onChange, fieldName}: any) => {
  const handleEditorInit = (editor: any) => {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return {
        upload: async () => {
          const data = new FormData();
          data.append("file", await loader.file);

          const response = await uploadImageBody(data)

          return {
            default: response.path,
          };
        },
      };
    };
  };

  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={watch(fieldName, '')}
        // config={{
        //   toolbar: ["heading", "|", "bold", "italic", "underline", "link", "|", "fontSize", "bulletedList", "numberedList", "blockQuote", "imageUpload", "insertTable", "mediaEmbed"],
        //   image: {
        //     toolbar: [
        //       'imageTextAlternative',
        //       'toggleImageCaption',
        //       'imageStyle:inline',
        //       'imageStyle:block',
        //       'imageStyle:side'
        //     ]
        //   },
        //   table: {
        //     contentToolbar: [
        //       'tableColumn',
        //       'tableRow',
        //       'mergeTableCells'
        //     ]
        //   },
        //   // fontSize: {
        //   //   options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
        //   // },
        // }}
        onReady={(editor) => handleEditorInit(editor)}
        onChange={(event, editor) => {
          const data = editor.getData();
          setValue(fieldName, data, { shouldValidate: true });
          onChange(data);
        }}
      />
    </>
  );
};

export default CKEditorComponent;
