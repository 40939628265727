import axios from '@libs/axios'
import { ContestList, ContestListParams, UpsertContestParams } from '@store/types/Contest'
const prefix = '/admin'
export const fetchContest = async (params: ContestListParams) => {
  const { data } = await axios.get<ContestList>(`${prefix}/contests`, {
    params
  })
  return data.data
}
export const createContest = async (params: UpsertContestParams) => {
  const { data } = await axios.post(`${prefix}/contests`, {
    ...params,
    id: undefined
  })
  return data
}
export const updateContest = async (params: UpsertContestParams) => {
  const contestId = params.id
  const { data } = await axios.put(`${prefix}/contests/${contestId}`, {
    ...params,
    id: undefined
  })
  return data
}
export const deleteContest = async (id: number) => {
  const { data } = await axios.delete(`${prefix}/contests/${id}`)
  if (data?.errors) {
    throw new Error(data?.message)
  }
  return data
}
export const fetchContestDetail = async (id: number) => {
  const { data } = await axios.get<any>(`${prefix}/contests/${id}`)
  if (data?.errors) {
    throw new Error(data?.message)
  }
  return data?.data
}
