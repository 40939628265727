const TOKEN_ITEM = "token";
const LANGUAGE_ITEM = "location";
export const setToken = (token: string, tokenType: string) => {
  const localToken = `${tokenType} ${token}`;
  localStorage.setItem(TOKEN_ITEM, localToken);
};
export const getToken = () => {
  return localStorage.getItem(TOKEN_ITEM);
};
export const removeToken = () => {
  localStorage.removeItem(TOKEN_ITEM);
};

export const setLanguage = (location: string) => {
  localStorage.setItem(LANGUAGE_ITEM, location);
};
export const getLanguage = () => {
  return localStorage.getItem(LANGUAGE_ITEM);
};
export const removeLanguage = () => {
  localStorage.removeItem(LANGUAGE_ITEM);
};
