import { z } from 'zod'
const ErrorMessageKeys = {
  MAX: 'MAX',
  MIN_ARRAYLENGTH: 'MIN_ARRAYLENGTH',
  MIN_VALUE: 'MIN_VALUE',
  MAX_VALUE: 'MAX_VALUE',
  REQUIRED: 'REQUIRED'
} as const

const errorMessage = (key: string, paramValue: any = ''): string => {
  return `ERROR_${key}${paramValue || paramValue === 0 ? '_' + paramValue : ''}`
}

const message = {
  max: (value: number, name: string) => errorMessage(ErrorMessageKeys.MAX, value),
  minArrayLength: (minLength: number, name: string) => errorMessage(ErrorMessageKeys.MIN_ARRAYLENGTH, minLength),
  minValue: (minValue: number, name: string) => errorMessage(ErrorMessageKeys.MIN_VALUE, minValue),
  maxValue: (maxValue: number, name: string) => errorMessage(ErrorMessageKeys.MAX_VALUE, maxValue),
  required: (name: string) => 'ERROR_REQUIRED'
}

export const CreateContestSchema = z.object({
  talent_ids: z.array(z.number()).min(1, { message: message.minArrayLength(1, 'Talent IDs') }),
  sheepn_param_ids: z.array(z.number()).min(1, { message: message.minArrayLength(1, 'SheepParam IDs') }),
  name: z
    .string()
    .max(20, { message: message.max(20, 'Name') })
    .min(1, { message: message.required('Name') }),
  description: z
    .string()
    .max(255, { message: message.max(255, 'Description') })
    .min(1, { message: message.required('Description') }),
  phase_1_start_date: z.string(),
  phase_1_end_date: z.string(),
  phase_2_start_date: z.string(),
  phase_2_end_date: z.string(),
  phase_3_start_date: z.string(),
  phase_3_end_date: z.string(),
  bonus_parameter: z
    .number({ invalid_type_error: 'ERROR_VALUE_AS_NUMBER' })
    .min(0, { message: message.minValue(0, 'Bonus parameter') })
    .max(100, { message: message.maxValue(100, 'Bonus parameter') }),
  bonus_talent: z
    .number({ invalid_type_error: 'ERROR_VALUE_AS_NUMBER' })
    .min(0, { message: message.minValue(0, 'Bonus talent') })
    .max(100, { message: message.maxValue(100, 'Bonus talent') }),
  maximum_incentive_bonus: z
    .number({ invalid_type_error: 'ERROR_VALUE_AS_NUMBER' })
    .min(0, { message: message.minValue(0, 'Maximum incentive bonus') })
    .max(100, { message: message.maxValue(100, 'Maximum incentive bonus') }),
  maximum_incentive_star: z
    .number({ invalid_type_error: 'ERROR_VALUE_AS_NUMBER' })
    .min(1, { message: message.minValue(1, 'Maximum incentive star') })
    .max(100, { message: message.maxValue(100, 'Maximum incentive star') })
})

export const UpdateContestSchema = z.object({
  talent_ids: z.array(z.number()).min(1, { message: message.minArrayLength(1, 'Talent IDs') }),
  sheepn_param_ids: z.array(z.number()).min(1, { message: message.minArrayLength(1, 'SheepnParam IDs') }),
  name: z
    .string()
    .max(20, { message: message.max(20, 'Name') })
    .min(1, { message: message.required('Name') }),
  description: z
    .string()
    .max(255, { message: message.max(255, 'Description') })
    .min(1, { message: message.required('Description') }),
  phase_1_start_date: z.string(),
  phase_1_end_date: z.string(),
  phase_2_start_date: z.string(),
  phase_2_end_date: z.string(),
  phase_3_start_date: z.string(),
  phase_3_end_date: z.string(),
  bonus_parameter: z
    .number({ invalid_type_error: 'ERROR_VALUE_AS_NUMBER' })
    .min(0, { message: message.minValue(0, 'Bonus parameter') })
    .max(100, { message: message.maxValue(100, 'Bonus parameter') }),
  bonus_talent: z
    .number({ invalid_type_error: 'ERROR_VALUE_AS_NUMBER' })
    .min(0, { message: message.minValue(0, 'Bonus talent') })
    .max(100, { message: message.maxValue(100, 'Bonus talent') }),
  maximum_incentive_bonus: z
    .number({ invalid_type_error: 'ERROR_VALUE_AS_NUMBER' })
    .min(0, { message: message.minValue(0, 'Maximum incentive bonus') })
    .max(100, { message: message.maxValue(100, 'Maximum incentive bonus') }),
  maximum_incentive_star: z
    .number({ invalid_type_error: 'ERROR_VALUE_AS_NUMBER' })
    .min(1, { message: message.minValue(1, 'Maximum incentive star') })
    .max(100, { message: message.maxValue(100, 'Maximum incentive star') })
})

type TCreateContest = z.TypeOf<typeof CreateContestSchema>
type TUpdateContest = z.TypeOf<typeof UpdateContestSchema>

export type { TCreateContest, TUpdateContest }
