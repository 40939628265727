import React, { useEffect, useState } from 'react'
import BaseSelect from '@atoms/Select'
import i18n from '@libs/i18n'
const DEFAULT_LANGUAGE = 'ja'
const Language = () => {
  const optionLanguage: { value: string; label: string }[] = [
    { label: 'Japanese', value: 'ja' },
    { label: 'English', value: 'en' }
  ]

  const [selectLanguage, setSelectLanguage] = useState('ja')

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    setSelectLanguage(lang)
  }

  useEffect(() => {
    const language = localStorage.getItem('language') || DEFAULT_LANGUAGE
    setSelectLanguage(language)
  }, [])

  return (
    <div>
      <BaseSelect
        options={optionLanguage}
        onChange={(value) => {
          handleChangeLanguage(value.value)
        }}
        value={selectLanguage}
        isSearchable={false}
      />
    </div>
  )
}

export default Language
