import React, { useEffect, useRef, useState } from 'react'
import BaseTable, { Header, TableRef } from '@atoms/Table'
import { INews, INewsList, NewsListParams } from '@store/types/News'
import { deleteNews, fetchNews } from '@store/news'
import Pagination from '@atoms/Pagination'
import showToast from '@utils/showToast'
import dayjs from 'dayjs'
import { Button } from 'react-bootstrap'
import { BsCheckCircle, BsPencilSquare, BsXCircle } from 'react-icons/bs'
import { TrashFill } from 'react-bootstrap-icons'
import Search from './components/FormSearch'
import ConfirmDialog, { ConfirmDialogRef } from '@components/ConfirmDialog'
import { AiFillDelete } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { userPaths } from '@routes/index'
import Dialog, { DialogRef } from '@components/Dialog'
import { useTranslation } from 'react-i18next'

type Props = {}

const News = (props: Props) => {
  const navigate = useNavigate()
  const baseTableRef = useRef<TableRef>(null)
  const [error, setError] = useState<string | null>(null)
  const { t } = useTranslation()
  const translation = (key: string, option?: any) => {
    const name = 'News'
    return t(`${name}.${key}`, option ?? {}) as string
  }

  const headers: Header[] = [
    {
      label: 'ID',
      key: 'id',
      width: '5'
    },
    {
      label: translation('THUMBNAIL'),
      key: 'id',
      width: '15',
      render: (value: INews) => (
        <div className='w-[80%] aspect-video rounded overflow-hidden'>
          <img src={value.image} className='w-full h-full' alt={value.title} />
        </div>
      )
    },
    {
      label: translation('TITLE'),
      key: 'title',
      width: '35',
      render: (value: INews) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={value.title}
          style={{ width: '100%', maxWidth: 500 }}
        >
          {value.title}
        </div>
      )
    },
    {
      label: translation('POST_DATE'),
      key: 'post_date',
      width: '20',
      render: (value: INews) => <div>{dayjs(value.post_date).format('YYYY-MM-DD')}</div>
    },
    {
      label: t('ACTIONS'),
      key: 'action_key',
      width: '15',
      render: (value: INews) => (
        <div className='flex flex-row gap-2'>
          <Button onClick={() => navigate(`${userPaths?.news}/${value.id}`)}>
            <BsPencilSquare />
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              confirmRef.current?.setOpen(true)
              if (confirmRef.current?.onOk) {
                confirmRef.current.onOk(() => {
                  onDeleteNews(value.id as number)
                })
              }
            }}
          >
            <TrashFill />
          </Button>
        </div>
      )
    }
  ]
  const [newsParams, setNewsParams] = useState<NewsListParams>({
    page: 1,
    page_size: 10
  })
  const [dataNews, setDataNews] = useState<INewsList>()
  const confirmRef = useRef<ConfirmDialogRef>(null)
  const successDialog = useRef<DialogRef>(null)
  const errorDialog = useRef<DialogRef>(null)
  const getListNews = async () => {
    try {
      baseTableRef.current?.setLoading(true)
      const data = await fetchNews(newsParams)
      setDataNews({
        ...data
      })
    } catch (error: any) {
      showToast('error', `Message : ${error?.message as string}` ?? 'Message : Error Unknown')
    } finally {
      baseTableRef.current?.setLoading(false)
    }
  }
  const onPageChange = (value: string | number) => {
    setNewsParams({
      ...newsParams,
      page: Number(value)
    })
  }
  const onSearch = (value: string) => {
    setNewsParams({
      ...newsParams,
      title: value,
      page: 1
    })
  }
  const onDeleteNews = async (id: number) => {
    try {
      confirmRef.current?.setLoading(true)
      await deleteNews(id)
      await getListNews()
      confirmRef.current?.setOpen(false)
      successDialog.current?.setOpen(true)
    } catch (error: any) {
      setError(error?.message ?? 'Error Unknown')
      errorDialog.current?.setOpen(true)
    } finally {
      confirmRef.current?.setLoading(false)
    }
  }

  useEffect(() => {
    getListNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsParams])

  return (
    <div className='w-full h-full flex flex-col gap-3'>
      <div>
        <div>
          <span className='font-bold text-2xl'>{t('NEWS')}</span>
        </div>
        <div className='flex flex-row justify-between items-center mt-3'>
          <Search onSearch={onSearch} />
          <Button variant='primary' onClick={() => navigate(`${userPaths?.news}/create`)} className='mb-3'>
            {translation('CREATE_NEWS')}
          </Button>
        </div>
      </div>
      <div className='flex-1 h-[50%]'>
        <BaseTable headers={headers} itemsRow={dataNews?.data?.result ?? []} ref={baseTableRef} />
      </div>

      <div className='flex justify-center'>
        <Pagination
          onPageChange={onPageChange}
          onChangePageSize={() => null}
          totalCount={dataNews?.data?.total || 0}
          currentPage={newsParams.page}
          siblingCount={1}
          pageSize={newsParams.page_size}
        />
      </div>

      <ConfirmDialog ref={confirmRef} cancelText={t('NO_CANCEL')} okText={t('YES_I_AM_SURE')}>
        <div className='flex flex-col items-center gap-2'>
          <AiFillDelete className='text-[4rem] text-gray-400' />
          <p className='mb-4'>{t('CONFIRM_DELETE')}</p>
        </div>
      </ConfirmDialog>
      <Dialog ref={successDialog} closeModal={false}>
        <div className='flex flex-col items-center gap-2'>
          <BsCheckCircle className='text-[6rem] text-green-500' />
          <p className='mb-4'>{translation('DELETE_NEWS_SUCCESS')}</p>
        </div>
        <div className='flex justify-center'>
          <Button className='min-w-[100px]' onClick={() => successDialog.current?.setOpen(false)}>
            OK
          </Button>
        </div>
      </Dialog>
      <Dialog ref={errorDialog}>
        <div className='flex flex-col items-center gap-2'>
          <BsXCircle className='text-[6rem] text-red-400' />
          <p className='mb-4'>{error}</p>
        </div>
        <div className='flex justify-center'>
          <Button
            className='min-w-[100px]'
            onClick={() => {
              errorDialog.current?.setOpen(false)
            }}
          >
            OK
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

export default News
