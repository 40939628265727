import { useEffect, useState } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

export interface BaseDatePickerProps extends Omit<ReactDatePickerProps, 'onChange'> {
  label?: string
  dateFormat?: string
  selectedDate: Date
  onChangeSelectedDate: (newDate: Date | null) => void
}
const DatePickerStyled = styled(DatePicker)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #dee2e6 !important;
  &:disabled {
    color: #212529;
    background-color: #e9ecef;
    opacity: 1;
  }
  &:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
`

const BaseDatePicker: React.FC<BaseDatePickerProps> = (props) => {
  const { label, dateFormat, selectedDate, onChangeSelectedDate } = props
  const [state, setState] = useState<Date | null>(selectedDate)
  const onChangeState = (newDate: Date | null) => {
    if (newDate) {
      setState(newDate)
      onChangeSelectedDate(newDate)
    }
  }
  useEffect(() => {
    setState(selectedDate)
  }, [selectedDate])
  return (
    <div className='flex flex-col gap-3 w-full'>
      {label && <span className='capitalize'>{label}</span>}
      <DatePickerStyled
        {...props}
        selected={state}
        onChange={(date) => onChangeState(date as any)}
        showTimeSelect
        className='border rounded p-2 w-full'
        dateFormat={dateFormat || 'yyyy-MM-dd HH:mm:ss'}
      />
    </div>
  )
}
export default BaseDatePicker
