export const objToOption = (
  obj: { [key: string]: string },
  valueAs?: 'number' | 'string'
): { value: any; label: string }[] => {
  return Object.keys(obj).map((item) => {
    return {
      label: item,
      value: valueAs === 'number' ? Number(obj[item]) : obj[item]
    }
  })
}
