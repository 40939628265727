import { useEffect, useState } from "react";
import i18n from "@libs/i18n";

export const LOCATION_ENUM = {
  en: "en-US",
  ja: "ja-JP",
};
export const useLanguage = () => {
  const [language, setLanguage] = useState("");
  const onChangeLanguage = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };
  useEffect(() => {
    onChangeLanguage(LOCATION_ENUM.ja);
  }, []);
  return {
    language,
    onChangeLanguage,
  };
};
