// LoadingOverlay.js
import React from 'react'
import styled, { keyframes } from 'styled-components'

const overlayAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const backdropAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: ${overlayAnimation} 0.3s ease-in-out;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  animation: ${backdropAnimation} 0.3s ease-in-out;
`

const Loading = () => {
  return (
    <>
      <Overlay>
        <div className='spinner-border text-primary' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </Overlay>
      <Backdrop></Backdrop>
    </>
  )
}

export default Loading
