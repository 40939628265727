import React from 'react'
import { userPaths } from '@routes/index'
import { useNavigate } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import { useRewardContext } from '@pages/Rewards/context'
const GoBackButton = () => {
  const navigate = useNavigate()
  const { translation } = useRewardContext()
  return (
    <button
      type='button'
      className='w-full flex items-center justify-center px-3 py-2 text-base text-blue-500 transition-colors duration-200 bg-white border-[1px] border-blue-500 rounded-lg gap-x-2 sm:w-auto hover:opacity-75'
      onClick={() => {
        navigate(`${userPaths.contests}`)
      }}
    >
      <BiArrowBack />
      <span className='whitespace-nowrap'>{translation('GO_TO_CONTEST_LIST')}</span>
    </button>
  )
}

export default GoBackButton
