import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiSelect, Option } from 'react-multi-select-component'
import styled from 'styled-components'

interface Props {
  options: any[]
  value: any[]
  onChange: (value: any[]) => void
  label?: string
  width?: string
  disabled?: boolean
}
export interface MultiSelectOpstion {
  label: string
  value: string
}
const MultiSelectStyled = styled(MultiSelect)`
  .dropdown-container[aria-disabled='true'] {
    color: #212529;
    background-color: #e9ecef;
    opacity: 1;
    border: 1px solid #dee2e6;
  }
  .dropdown-container {
    border: 1px solid #dee2e6;
  }
  .dropdown-container:focus-within {
    border-color: #86b7fe !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
`
const BaseMultiSelect: React.FC<Props> = (props) => {
  const { label, options, value, onChange, width, disabled } = props
  const [valueState, setValueState] = useState(value)
  const { t } = useTranslation()
  const onChangeValue = (v: any[]) => {
    setValueState(v)
    onChange(v)
  }
  useEffect(() => {
    setValueState(value)
  }, [value])
  return (
    <div
      className='flex flex-col gap-3'
      style={{
        width: width || '320px'
      }}
    >
      {label && <span>{label}</span>}
      <MultiSelectStyled
        options={options}
        overrideStrings={{ selectAll: t('ALL'), search: t('SEARCH') }}
        value={valueState}
        onChange={onChangeValue}
        labelledBy={label ?? ''}
        disabled={disabled}
        valueRenderer={(val: Option[], op: Option[]) => {
          return (
            <>
              {val.length === 0 ? (
                `${t('SELECT')}...`
              ) : (
                <>
                  {val.length === op.length
                    ? t('ALL_ITEMS_ARE_SELECTED')
                    : val.map((item, index) => {
                        return index === 0 ? item.label : ', ' + item.label
                      })}
                </>
              )}
            </>
          )
        }}
      />
    </div>
  )
}
export default BaseMultiSelect
