/* eslint-disable */
import { ITEM_TYPE } from '@constants/index'
import { useLoading } from '@layouts/Loading'
import { fetchTalents } from '@store/talents'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
interface RewardContextType {
  rarity: { value: string | number; label: string }[]
  type: { value: string | number; label: string }[]
  items: { value: string | number; label: string }[]
  translation: (key: string, params?: any) => string
}
export const RewardContext = React.createContext<RewardContextType | undefined>(undefined)

interface RewardContextProps {
  children: React.ReactNode | React.ReactElement
}

export const useRewardContext = () => {
  const context = useContext(RewardContext)
  if (!context) {
    throw new Error('useRewardContext must be used within a RewardContextProvider')
  }
  return context
}
export interface OptionType {
  value: number
  label: string
}
export const RewardContextProvider: React.FC<RewardContextProps> = ({ children }) => {
  const [type, setType] = useState<OptionType[]>([])

  const { disposeLoading, onLoading } = useLoading()

  const { t } = useTranslation()
  const translation = (key: string, params?: any) => {
    const name = 'Rewards'
    return t(`${name}.${key}`, params ? params : {}) as string
  }

  const getTalents = async () => {
    try {
      onLoading()
      const { result } = await fetchTalents()
      if (result) {
        let option = result.map((item) => {
          return {
            value: item.id,
            label: item.name
          }
        })
        setType(option)
      } else {
        setType([])
      }
    } catch (error) {
      setType([])
    } finally {
      disposeLoading()
    }
  }

  const RARITY: { value: number; label: string }[] = [
    {
      value: 1,
      label: `${t('RARITY')} ★ 1`
    },
    {
      value: 2,
      label: `${t('RARITY')} ★ 2`
    },
    {
      value: 3,
      label: `${t('RARITY')} ★ 3`
    },
    {
      value: 4,
      label: `${t('RARITY')} ★ 4`
    },
    {
      value: 5,
      label: `${t('RARITY')} ★ 5`
    }
  ]

  useEffect(() => {
    getTalents()
  }, [])

  const contextValue: RewardContextType = {
    items: ITEM_TYPE,
    rarity: RARITY,
    type,
    translation
  }

  return <RewardContext.Provider value={contextValue}>{children}</RewardContext.Provider>
}
