import { usePagination, DOTS } from './usePagination'
import { Button } from 'react-bootstrap'
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'

// const PAGE_SIZES = [10, 20, 50, 100];
interface Props {
  onPageChange: (value: string | number) => void
  onChangePageSize: (value: string | number) => void
  totalCount: number
  siblingCount: number
  currentPage: number
  pageSize: number
}
const Pagination = (props: Props) => {
  const { t } = useTranslation()
  const {
    onPageChange,
    totalCount,
    siblingCount,
    currentPage,
    pageSize
    // onChangePageSize,
  } = props

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (currentPage === 0 || paginationRange.length < 1) {
    return null
  }
  const lastPage = paginationRange[paginationRange.length - 1]
  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  return (
    <div className='flex flex-row gap-3 items-center'>
      <Button disabled={currentPage === 1} variant='outline-primary' onClick={onPrevious}>
        <ArrowLeft />
      </Button>
      {paginationRange.map((pageNumber: string | number, index: number) => {
        if (pageNumber === DOTS) {
          return (
            <li className='pagination-item dots' key={index}>
              &#8230;
            </li>
          )
        }

        return (
          <Button
            className=''
            variant={pageNumber === currentPage ? 'primary' : 'outline-primary'}
            onClick={() => (pageNumber !== currentPage ? onPageChange(pageNumber) : null)}
            key={index}
          >
            <span>{pageNumber}</span>
          </Button>
        )
      })}
      <Button disabled={currentPage === lastPage} variant='outline-primary' onClick={onNext}>
        <ArrowRight />
      </Button>
      <div>
        {t('TOTAL')}: {totalCount} | {t('PAGE_SIZE')}: {pageSize}
      </div>
    </div>
  )
}

export default Pagination
