import { userPaths } from '@routes/index'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

interface Props {
  onLogout: () => void
}

const NavBar: React.FC<Props> = (props) => {
  const { onLogout } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const navs = [
    // {
    //   label: 'Admin',
    //   path: userPaths.admin
    // },
    // {
    //   label: 'App Version',
    //   path: userPaths.appVersion
    // },
    // {
    //   label: 'User',
    //   path: userPaths.users
    // },
    // {
    //   label: 'Roles',
    //   path: userPaths.roles
    // },
    // {
    //   label: 'Items',
    //   path: userPaths.items
    // },
    {
      label: t('CONTEST'),
      path: userPaths.contests
    },
    {
      label: t('NOTIFICATION'),
      path: userPaths.notification
    },
    {
      label: t('NEWS'),
      path: userPaths.news
    }
  ]
  const onClick = (path: string) => {
    navigate(path)
  }
  return (
    <div className='flex flex-col w-full h-full'>
      {navs.map((nav) => (
        <div
          className={`p-3 cursor-pointer hover:bg-gray-300 ${location.pathname === nav.path ? 'bg-gray-300' : ''}`}
          onClick={() => onClick(nav.path)}
          key={nav.path}
        >
          <span>{nav.label}</span>
        </div>
      ))}
      <div className='flex mt-auto mb-3 justify-center'>
        <Button onClick={onLogout}>Logout</Button>
      </div>
    </div>
  )
}
export default NavBar
