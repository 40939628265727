import NavBar from "@layouts/NavBar";
import Header from "@layouts/Header";

interface Props {
  children: JSX.Element;
  onLogout: () => void;
}

const Layouts: React.FC<Props> = (props) => {
  const { children, onLogout } = props;
  return (
    <div className="w-screen h-screen">
      <div className="w-full h-[5rem] border-b-2">
        <Header />
      </div>
      <div className="flex flex-row w-full h-[calc(100vh-5rem)]">
        <div className={`w-[10rem] h-full border-r-2`}>
          <NavBar onLogout={onLogout} />
        </div>
        <div
          className={`w-[calc(100vw-10rem)] h-full overflow-auto p-3 flex flex-col`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export default Layouts;
