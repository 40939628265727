import React, { useCallback, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { TUpdateNews, UpdateNewsSchema } from '../schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import Button from '@atoms/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { userPaths } from '@routes/index'
import UploadImage from '../components/UploadImage'
import { fetchNewsDetail, updateNews } from '@store/news'
import { useErrorContext } from '@context/Error'
import { useLoading } from '@layouts/Loading'
import { INews } from '@store/types/News'
import { useNotification } from '@context/Notification'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CKEditorComponent from '@atoms/CKEditor'

const NewsContent = styled.div`
  .ck-content {
    min-height: 350px;
    max-height: 800px;
  }

  .table {
    width: auto;
    margin-left: 0;

    table {
      border: 0.01px solid rgb(38, 38, 38);
    }

    th, td {
      background: transparent;
      min-width: 140px !important;
      padding: 10px !important;
      max-width: 250px;
    }
  }
`

const EditNews: React.FC = () => {
  const errorContext = useErrorContext()
  const { onLoading, disposeLoading } = useLoading()
  const navigate = useNavigate()
  const params = useParams()
  const [newDetailDefault, setNewDetailDefault] = useState<INews | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    setValue,
    watch
  } = useForm<TUpdateNews>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(UpdateNewsSchema)
  })
  const { t } = useTranslation()
  const translation = (key: string, option?: any) => {
    const name = 'News'
    return t(`${name}.${key}`, option ?? {}) as string
  }

  const { showNotification } = useNotification()

  const fetchApiContestDetail = useCallback(
    async (id: number) => {
      try {
        onLoading()
        const newsDetail = await fetchNewsDetail(id)
        if (newsDetail) {
          setValue('title', newsDetail?.title as string)
          setValue('body', newsDetail?.body as string)
          setNewDetailDefault(newsDetail)
        }
      } catch (error) {
        errorContext.setError(new Error(error as string))
      } finally {
        disposeLoading()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorContext]
  )

  useEffect(() => {
    fetchApiContestDetail(params?.id as unknown as number)
    return () => {
      setNewDetailDefault(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      const formData = new FormData()
      if (data.file instanceof File) {
        formData.append('file', data.file)
      }
      formData.append('title', data.title)
      formData.append('body', data.body)
      await updateNews(newDetailDefault?.id as number, formData)
      showNotification({
        message: translation('EDIT_NEWS_SUCCESS'),
        type: 'success',
        onOk() {
          navigate(`${userPaths?.news}`)
        }
      })
    } catch (error: any) {
      showNotification({
        message: error?.message ?? 'Error Unknown',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const validationRules = {
    required: translation('BODY_REQUIRED'),
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedTitle = event.target.value.trimStart()
    setValue('title', trimmedTitle, { shouldValidate: true, shouldDirty: true })
  }

  return (
    <>
      {newDetailDefault !== null || loading ? (
        <NewsContent className='w-full h-full flex flex-col gap-3 news-content'>
          <h1 className='font-bold text-2xl mb-4'>{translation('EDIT_NEWS')}</h1>
          <Form className='w-full flex flex-col gap-3' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>{translation('IMAGE')}</Form.Label>
              <Controller
                name='file'
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <UploadImage
                      image={newDetailDefault?.image ?? ''}
                      setValue={(file) => {
                        onChange(file)
                      }}
                      className='w-[100%] aspect-[5/2]'
                    />
                  )
                }}
              />

              <Form.Text className='text-danger mt-2 block'>{errors.file?.message ? translation(`${errors.file?.message}`) : ''}</Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>{translation('TITLE')}</Form.Label>
              <Form.Control type='text' placeholder='Enter title' {...register('title')} onChange={handleTitleChange} />
              <Form.Text className='text-danger mt-2 block'>{errors.title?.message ? translation(`${errors.title?.message}`) : ''}</Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>{translation('BODY')}</Form.Label>
              <Controller
                name='body'
                rules={validationRules}
                control={control}
                render={({field: { onChange }}) => {
                  return (
                    <CKEditorComponent
                      watch={watch}
                      setValue={setValue}
                      onChange={onChange}
                      fieldName='body'
                    />
                  )
                }}
              />

              <Form.Text className='text-danger mt-2 block'>{errors.body?.message ? translation(`${errors.body?.message}`) : ''}</Form.Text>
            </Form.Group>
            <Form.Group className='mb-3  w-full flex justify-end'>
              <div className='action flex gap-2'>
                <Button className='mb-3 min-w-[80px]' type='submit' loading={loading} disabled={!isDirty || !isValid}>
                {t('SAVE')}
                </Button>
                <Button
                  className='mb-3 btn-danger min-w-[80px]'
                  type='button'
                  onClick={() => navigate(`${userPaths?.news}`)}
                  disabled={loading}
                >
                  {t('CANCEL')}
                </Button>
              </div>
            </Form.Group>
          </Form>
        </NewsContent>
      ) : (
        <></>
      )}
    </>
  )
}

export default EditNews
