import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { FastOmit } from 'styled-components/dist/types'
interface ButtonProps {
  className?: string
  loading?: boolean
  children: any
}
const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  .spinner-border {
    width: 14px;
    height: 14px;
    border: 2px solid currentcolor;
    border-right-color: transparent;
  }
`
const Button = ({
  className,
  loading = false,
  children,
  disabled,
  ...rest
}: ButtonProps & FastOmit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, never>) => {
  return (
    <ButtonStyled {...rest} disabled={loading || disabled} className={classNames('btn btn-primary', className)}>
      {loading && (
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      )}
      <span>{children}</span>
    </ButtonStyled>
  )
}

export default Button
