import React, { useEffect, useRef, useState } from 'react'
import ClassReward from './components/Class'
import { Accordion, Button, Form } from 'react-bootstrap'
import { RewardContextProvider } from './context'
import { RankGroup, RootSchema } from './schema'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import GoBackButton from './components/GoBack'
import { getRewardsById, updateRewardsById } from '@store/rewards'
import { useParams } from 'react-router-dom'
import { useLoading } from '@layouts/Loading'
import Dialog, { DialogRef } from '@components/Dialog'
import { useErrorContext } from '@context/Error'
import { PiWarningCircle } from 'react-icons/pi'
import { useNotification } from '@context/Notification'
import { parseNumbersToDecimal } from '@utils/parseNumbers'
import { userPaths } from '@routes/index'
import { useTranslation } from 'react-i18next'

const Reward = () => {
  const [dataDefault, setDataDefault] = useState<{ rank_groups: RankGroup[] }>({ rank_groups: [] })
  const [activeKey, setActiveKey] = useState<string | null>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation()
  const translation = (key: string) => {
    const name = 'Rewards'
    return t(`${name}.${key}`)
  }

  const method = useForm<{ rank_groups: RankGroup[] }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(RootSchema)
  })

  const { disposeLoading, onLoading } = useLoading()

  const confirmDialog = useRef<DialogRef>(null)

  const selectedKeyRef = useRef('')

  const errorContext = useErrorContext()

  const { showNotification } = useNotification()

  const {
    watch,
    setValue,
    trigger,
    clearErrors,
    reset,
    formState: { isDirty }
  } = method

  const values = watch(['rank_groups'])?.[0]

  const params = useParams()

  const fetchDataRewards = async (id: number) => {
    try {
      onLoading()
      reset()
      const rs = await getRewardsById(id)
      let keysToParse: string[] = ['gspt_amount', 'spt_amount']
      let parsedData: RankGroup[] = (rs?.rank_groups as RankGroup[]).map((item) => {
        return {
          ...item,
          contest_rewards: item.contest_rewards.map((reward) => {
            return parseNumbersToDecimal(reward, keysToParse)
          })
        }
      })
      setDataDefault(rs as any)
      setValue('rank_groups', parsedData)
    } catch (error: any) {
      setValue('rank_groups', [])
      if (error?.errors?.code === 404) {
        errorContext.setError(new Error(translation('REWARDS_CONFIGURATION_NOT_FOUND')), userPaths?.contests)
      } else {
        errorContext.setError(new Error(error?.message ?? 'ERROR UNKNOWN'), userPaths?.contests)
      }
    } finally {
      disposeLoading()
    }
  }

  useEffect(() => {
    fetchDataRewards(params?.id as unknown as number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  const updateRewards = async (data: RankGroup) => {
    try {
      setLoading(true)
      const cloneDataDefault = { ...dataDefault }
      const index = cloneDataDefault.rank_groups.findIndex((item) => {
        return item.id === data.id
      })

      cloneDataDefault.rank_groups[index] = data

      await updateRewardsById(params?.id as unknown as number, cloneDataDefault)

      setLoading(false)

      showNotification({
        message: translation('EDIT_CONFIG_REWARDS_SUCCESSFULLY'),
        type: 'success',
        onOk() {
          fetchDataRewards(params?.id as unknown as number)
        }
      })
    } catch (error: any) {
      setLoading(false)
      showNotification({
        message: error?.error_code ? translation(error?.error_code || error?.message) : 'Error Unknown',
        type: 'error'
      })
    }
  }

  const handleAccordionSelect = (key: string | null) => {
    setActiveKey(key === activeKey ? null : key)

    setTimeout(() => {
      // Scroll to the Accordion Header corresponding to the selected key
      const headerElement = document.getElementById(`accordion-header-${key}`)
      if (headerElement) {
        headerElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }, 500) // Delay of 0.5 seconds (500 milliseconds)
  }
  const closeRewards = () => {
    fetchDataRewards(params?.id as unknown as number)
    if (selectedKeyRef.current === activeKey) {
      handleAccordionSelect('')
    } else {
      handleAccordionSelect(selectedKeyRef.current)
    }
    const index = values.findIndex((item) => item.id === Number(selectedKeyRef.current))
    if (index !== -1) {
      clearErrors()
      trigger(`rank_groups.${index}.contest_rewards`)
    }
  }

  return (
    <RewardContextProvider>
      <div className='w-full h-fit mb-4'>
        <div className='header flex justify-between mb-2'>
          <h1 className='title font-bold text-2xl capitalize'>{translation('REWARDS_CONFIGURATION')}</h1>
          <GoBackButton />
        </div>
        <Accordion
          activeKey={activeKey}
          onSelect={(e: any) => {
            const selectedKey = e?.[0]

            if (activeKey === null || activeKey === '') {
              handleAccordionSelect(selectedKey)
              const index = values.findIndex((item) => item.id === Number(selectedKey))
              if (index !== -1) {
                clearErrors()
                trigger(`rank_groups.${index}.contest_rewards`)
              }
            } else if (!isDirty) {
              handleAccordionSelect(selectedKey)
              const index = values.findIndex((item) => item.id === Number(selectedKey))
              if (index !== -1) {
                clearErrors()
                trigger(`rank_groups.${index}.contest_rewards`)
              }
            } else {
              confirmDialog.current?.setOpen(true)
              selectedKeyRef.current = selectedKey
            }
          }}
          alwaysOpen
        >
          <FormProvider {...method}>
            <Form>
              {values ? (
                <>
                  {values.map((item, index) => {
                    return (
                      <React.Fragment key={item?.id}>
                        <Accordion.Item eventKey={item?.id as string}>
                          <Accordion.Header id={`accordion-header-${item?.id}`}>RG - {item.name}</Accordion.Header>
                          <Accordion.Body>
                            <ClassReward
                              index={index}
                              updateRewards={(data: RankGroup) => {
                                updateRewards(data)
                              }}
                              loading={loading}
                              closeRewards={() => {
                                if (isDirty) {
                                  confirmDialog.current?.setOpen(true)
                                } else {
                                  clearErrors()
                                  trigger(`rank_groups.${index}.contest_rewards`)
                                  handleAccordionSelect('')
                                }
                              }}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      </React.Fragment>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </Form>
          </FormProvider>
        </Accordion>
      </div>
      <Dialog ref={confirmDialog}>
        <div className='flex flex-col items-center gap-2'>
          <PiWarningCircle className='text-[8rem] text-yellow-400' />
          <p className='mb-4 text-center'>{translation('WARRING_CLOSE_REWARD')}</p>
        </div>
        <div className='flex justify-center gap-3'>
          <Button
            className='min-w-[100px]'
            onClick={() => {
              closeRewards()
              confirmDialog.current?.setOpen(false)
            }}
          >
            OK
          </Button>
          <Button className='min-w-[100px] btn-danger' onClick={() => confirmDialog.current?.setOpen(false)}>
            {t('CANCEL')}
          </Button>
        </div>
      </Dialog>
    </RewardContextProvider>
  )
}

export default Reward
