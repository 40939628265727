import Loading from '@components/Loading'
import { createContext, useContext, useState } from 'react'

type LoadingContextProps = {
  onLoading: () => void
  disposeLoading: () => void
}
export const LoadingContext = createContext<LoadingContextProps>({
  onLoading: () => null,
  disposeLoading: () => null
})
type Props = {
  children: JSX.Element | JSX.Element[]
}
const LoadingProvider: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { children } = props
  const onLoading = () => {
    setIsLoading(true)
  }
  const disposeLoading = () => {
    setIsLoading(false)
  }
  return (
    <LoadingContext.Provider value={{ onLoading, disposeLoading }}>
      {isLoading ? <Loading /> : <></>}
      <>{children}</>
    </LoadingContext.Provider>
  )
}
export default LoadingProvider
export const useLoading = () => useContext(LoadingContext)
