import { useFieldArray, useFormContext } from 'react-hook-form'
import InputNumber from '@components/Form/InputNumber'
import Select from '@components/Form/Select'
import { useRewardContext } from '@pages/Rewards/context'
import { has } from 'lodash'
import React from 'react'

type Props = {
  index: number
  name: string
  trigger?: () => void
}

const Eggs = ({ name }: Props) => {
  const { register, control } = useFormContext()
  const { rarity, type } = useRewardContext()
  const { fields } = useFieldArray({
    control,
    name: name,
    keyName: '_id'
  })
  return (
    <div>
      <ul>
        {fields.length === 0 ? (
          ''
        ) : (
          <>
            {fields.map((item, k) => {
              return (
                <React.Fragment key={`${item._id}`}>
                  {!has(item, '_destroy') ? (
                    <li className='mb-2'>
                      <div className='flex gap-2'>
                        <div className='col-3'>
                          <InputNumber
                            register={register(`${name}.${k}.amount`, { valueAsNumber: true })}
                            label=''
                            showLabel={false}
                            showError={false}
                            disabled
                          />
                        </div>
                        <div className='col-4'>
                          <Select
                            options={rarity}
                            control={control}
                            showLabel={false}
                            name={`${name}.${k}.rarity_id`}
                            label={`${name}.${k}.rarity_id`}
                            disabled
                          />
                        </div>
                        <div className='col-4'>
                          <Select
                            options={type}
                            control={control}
                            showLabel={false}
                            name={`${name}.${k}.talent_id`}
                            label={`${name}.${k}.talent_id`}
                            disabled
                          />
                        </div>
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              )
            })}
          </>
        )}
      </ul>
    </div>
  )
}

export default Eggs
