import Language from '@components/Language'
import { useNavigate } from 'react-router-dom'

interface Props {}

const Header: React.FC<Props> = () => {
  const navigate = useNavigate()
  const goHome = () => {
    navigate('/home')
  }
  return (
    <div className='flex flex-row w-full h-full justify-between items-center'>
      <span className='h-fit self-center text-3xl font-bold p-3 cursor-pointer' onClick={goHome}>
        Sleepass Admin
      </span>
      <div className='pr-3'>
        <Language />
      </div>
    </div>
  )
}
export default Header
