import { forwardRef } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

interface Props {
  options?: { value: string | number | boolean; label: string }[]
  onChange?: (value: any) => void
  placeholder?: string
  value?: string | number | boolean
  disabled?: boolean
  isSearchable?: boolean
}
const SelectStyled = styled(Select)``
const BaseSelect = forwardRef<any, Props>((props, ref) => {
  const { options, onChange, placeholder, value, disabled = false, isSearchable = true } = props
  return (
    <SelectStyled
      ref={ref}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      value={options?.find((item) => item.value === value)}
      isDisabled={disabled}
      isSearchable={isSearchable}
      styles={{
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            borderWidth: 1,
            borderColor: state.isFocused ? '#86b7fe' : '#dee2e6',
            borderRadius: '0.375rem',
            boxShadow: `0 0 0 0.25rem ${state.isFocused ? 'rgba(13, 110, 253, 0.25)' : 'rgba(0, 0, 0, 0)'}`,
            ':hover': {
              borderColor: state.isFocused ? '#86b7fe' : '#dee2e6'
            },
            background: state.isDisabled ? '#e9ecef' : 'hsl(0, 0%, 100%)'
          }
        },
        indicatorSeparator() {
          return {
            backgroundColor: 'transparent'
          }
        },
        singleValue(base, props) {
          return {
            ...base,
            color: props.isDisabled ? 'currentcolor' : 'currentcolor'
          }
        },
        menu: (baseStyles, state) => {
          return {
            ...baseStyles,
            zIndex: 10
          }
        }
      }}
    />
  )
})
export default BaseSelect
