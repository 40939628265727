import BigNumber from 'bignumber.js'
import _ from 'lodash'

const parseNumbersFloat = (obj: any, keysToParse: string[]): any => {
  return _.mapValues(obj, (value: any, key: string) => {
    if (keysToParse.includes(key) && typeof value === 'string' && !isNaN(parseFloat(value))) {
      return Number(value)
    }
    return value
  })
}

const parseNumbersToDecimal = (obj: any, keysToParse: string[]): any => {
  return _.mapValues(obj, (value: any, key: string) => {
    if (keysToParse.includes(key) && typeof value === 'string' && !isNaN(parseFloat(value))) {
      return new BigNumber(value).toFixed()
    }
    return value
  })
}
export { parseNumbersFloat, parseNumbersToDecimal }
