import InputNumber from '@components/Form/InputNumber'
import { Form } from 'react-bootstrap'
import Eggs from '../Eggs'
// import Items from '../Items'
import { useFormContext } from 'react-hook-form'
import Input from '@components/Form/Input'
import BigNumber from '@libs/bignumber'
import { useRewardContext } from '@pages/Rewards/context'

type Props = {
  index: number
  name: string
}

const FormReward = ({ index, name }: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const { translation } = useRewardContext()

  return (
    <div className='mb-3'>
      <div className='row mb-3'>
        <div className='col-12 col-xl-6'>
          <Form.Group className='mb-3 w-full'>
            <InputNumber
              label={translation('HIGHEST_PLACE')}
              register={register(`${name}.[${index}].highest_place`, { valueAsNumber: true, required: true })}
              errors={errors}
              disabled
            />
          </Form.Group>
        </div>
        <div className='col-12 col-xl-6'>
          <Form.Group className='mb-3 w-full'>
            <InputNumber
              label={translation('LOWEST_PLACE')}
              register={register(`${name}.[${index}].lowest_place`, {
                setValueAs: (value) => (value === '' || value === null ? null : Number(value))
              })}
              errors={errors}
              showError={false}
              disabled
            />
          </Form.Group>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12 col-xl-6'>
          <Form.Group controlId='form-eggs-rewards' className='mb-3 w-full'>
            <Form.Group controlId={`${name}.[${index}].spt_amount`} className='mb-3 w-full'>
              <Input
                label={translation('SPT_LABEL')}
                register={register(`${name}.[${index}].spt_amount`, {
                  setValueAs: (value) => {
                    return value === '' || value === null ? 0 : new BigNumber(value).toFixed()
                  }
                })}
                disabled
                showError={false}
                errors={errors}
              />
            </Form.Group>
          </Form.Group>
        </div>
        <div className='col-12 col-xl-6'>
          <Form.Group controlId={`${name}.[${index}].gspt_amount`} className='mb-3 w-full'>
            <Input
              label={translation('GSPT_LABEL')}
              register={register(`${name}.[${index}].gspt_amount`, {
                setValueAs: (value) => {
                  return value === '' || value === null ? 0 : new BigNumber(value).toFixed()
                }
              })}
              disabled
              errors={errors}
            />
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-xl-6'>
          <Form.Group controlId={`${name}.[${index}].eggs`} className='mb-3 w-full'>
            <Form.Label className='text-base'>{translation('EGGS')}</Form.Label>
            <Eggs index={index} name={`${name}.[${index}].contest_rewards_eggs`} />
          </Form.Group>
        </div>
        <div className='col-12 col-xl-6'>
          {/* <Form.Group controlId={`${name}.[${index}].items`} className='mb-3 w-full'>
            <Form.Label className='text-base'>{translation('ITEMS')}</Form.Label>
            <Items index={index} name={`${name}.[${index}].contest_rewards_items`} />
          </Form.Group> */}
        </div>
      </div>
    </div>
  )
}

export default FormReward
