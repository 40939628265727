import axios from '@libs/axios'
import { IListNotificationParams } from './types/Notification'

const prefix = '/admin/notifications'

const getListNotification = async (params: IListNotificationParams) => {
  const { data } = await axios.get(`${prefix}`, {
    params
  })
  if (data?.errors) {
    throw new Error(data?.message ?? 'ERROR UNKNOWN')
  }
  return data
}

const getNotificationById = async (id: string | number) => {
  const { data } = await axios.get(`${prefix}/${id}`)
  return data
}

const createNotification = async (dataCreate: any) => {
  const { data } = await axios.post(`${prefix}`, dataCreate)
  if (data?.errors) {
    throw new Error(data?.message ?? 'Error Unknown')
  }
  return data
}

const updateNotificationById = async (id: string | number, dataUpdate: any) => {
  const { data } = await axios.put(`${prefix}/${id}`, dataUpdate)
  if (data?.errors) {
    throw new Error(data?.message ?? 'Error Unknown')
  }
  return data
}

const deleteNotificationById = async (id: string | number) => {
  const { data } = await axios.delete(`${prefix}/${id}`)
  if (data?.errors) {
    throw new Error(data?.message ?? 'Error Unknown')
  }
  return data
}
export { getListNotification, getNotificationById, createNotification, updateNotificationById, deleteNotificationById }
