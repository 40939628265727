import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import timezonePlugin from 'dayjs/plugin/timezone'
dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)

function convertUTCToLocal(utcTime: string, formatDate?: string): string {
  // Chuyển đổi thời gian từ UTC+0 sang múi giờ local
  return dayjs
    .utc(utcTime)
    .local()
    .format(formatDate ?? 'YYYY-MM-DD HH:mm:ss')
}

function revertToUTC(localTime: string, formatDate?: string): string {
  // Chuyển đổi thời gian từ múi giờ local sang UTC+0
  return dayjs(localTime)
    .utc()
    .format(formatDate ?? 'YYYY-MM-DD HH:mm:ss')
}

export { convertUTCToLocal, revertToUTC }
