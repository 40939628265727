import { BrowserRouter } from 'react-router-dom'
import Authentication from '@layouts/Authentication'
import LoadingProvider from '@layouts/Loading'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ErrorContextProvider } from './context/Error'
import { NotificationProvider } from '@context/Notification'

const App = () => {
  return (
    <LoadingProvider>
      <BrowserRouter>
        <ErrorContextProvider>
          <NotificationProvider>
            <ToastContainer />
            <Authentication />
          </NotificationProvider>
        </ErrorContextProvider>
      </BrowserRouter>
    </LoadingProvider>
  )
}

export default App
