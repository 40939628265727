import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { CreateNewsSchema, TCreateNews } from '../schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import Button from '@atoms/Button'
import { useNavigate } from 'react-router-dom'
import { userPaths } from '@routes/index'
import UploadImage from '../components/UploadImage'
import { createNews } from '@store/news'
import { useNotification } from '@context/Notification'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CKEditorComponent from '@atoms/CKEditor'

const NewsContent = styled.div`
  .ck-content {
    min-height: 350px;
    max-height: 800px;
  }

  .table {
    width: auto;
    margin-left: 0;

    table {
      border: 0.01px solid rgb(38, 38, 38);
    }

    th, td {
      background: transparent;
      min-width: 140px !important;
      padding: 10px !important;
      max-width: 250px;
    }
  }
`

const CreateNews: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const translation = (key: string, option?: any) => {
    const name = 'News'
    return t(`${name}.${key}`, option ?? {}) as string
  }

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isDirty, isValid }
  } = useForm<TCreateNews>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(CreateNewsSchema)
  })
  const [loading, setLoading] = useState<boolean>(false)

  const { showNotification } = useNotification()

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedTitle = event.target.value.trimStart()
    setValue('title', trimmedTitle, { shouldValidate: true, shouldDirty: true })
  }

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', data.file)
      formData.append('title', data.title)
      formData.append('body', data.body)
      await createNews(formData)
      showNotification({
        message: translation('CREATE_NEWS_SUCCESS'),
        type: 'success',
        onOk() {
          navigate(`${userPaths?.news}`)
        }
      })
    } catch (error: any) {
      showNotification({
        message: error?.message ?? 'Error Unknown',
        type: 'error'
      })
    } finally {
      setLoading(false)
    }
  }
  const validationRules = {
    required: translation('BODY_REQUIRED'),
  };

  return (
    <NewsContent className='w-full h-full flex flex-col gap-3'>
      <h1 className='font-bold text-2xl mb-4'>{translation('CREATE_NEWS')}</h1>
      <Form className='w-full flex flex-col gap-3' onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label>{translation('IMAGE')}</Form.Label>
          <Controller
            name='file'
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <UploadImage
                  setValue={(file) => {
                    onChange(file)
                  }}
                  className='w-[100%] aspect-[5/2]'
                />
              )
            }}
          />

          <Form.Text className='text-danger mt-2 block'>{errors.file?.message ? translation(`${errors.file?.message}`) : ''}</Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>{translation('TITLE')}</Form.Label>
          <Form.Control type='text' placeholder={translation('ENTER_TITLE')} {...register('title')} onChange={handleTitleChange} />
          <Form.Text className='text-danger mt-2 block'>{errors.title?.message ? translation(`${errors.title?.message}`) : ''}</Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Label>{translation('BODY')}</Form.Label>
          <Controller
            name='body'
            control={control}
            rules={validationRules}
            render={({field: { onChange }}) => {
              return (
                <CKEditorComponent
                  watch={watch}
                  setValue={setValue}
                  onChange={onChange}
                  fieldName='body'
                />
              )
            }}
          />

          <Form.Text className='text-danger mt-2 block'>{errors.body?.message ? translation(`${errors.body?.message}`) : ''}</Form.Text>
        </Form.Group>
        <Form.Group className='mb-3  w-full flex justify-end'>
          <div className='action flex gap-2'>
            <Button className='mb-3 min-w-[80px]' type='submit' loading={loading} disabled={!isDirty || !isValid}>
              {t('CREATE')}
            </Button>
            <Button
              className='mb-3 btn-danger min-w-[80px]'
              type='button'
              onClick={() => navigate(`${userPaths?.news}`)}
              disabled={loading}
            >
              {t('CANCEL')}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </NewsContent>
  )
}

export default CreateNews
