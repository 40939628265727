import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  authPaths,
  authPathsArr,
  testPathsArr,
  userPaths,
  userPathsArr,
} from "@routes/index";
import { getToken, removeToken, setToken } from "@utils/localStorage";
import { useLanguage } from "./useLanguage";
import { login } from "@store/auth";
import { useLoading } from "@layouts/Loading";
import { loginAdmin } from "@store/admin";
import {useNotification} from "@context/Notification";
import { get } from 'lodash'

export const useAuthentication = () => {
  useLanguage();
  const [isAuth, setIsAuth] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { onLoading, disposeLoading } = useLoading();
  const { showNotification } = useNotification()
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

  const updateIsAuth = (value: boolean) => {
    setIsAuth(value);
  };

  const onLogout = () => {
    setIsAuth(false);
    removeToken();
    navigate(authPaths.login);
  };
  const onLogin = async (
    xIdToken: string,
    xWalletAddress: string,
    publicKey: string
  ) => {
    onLoading();
    try {
      const data = await login({
        xIdToken,
        xWalletAddress,
        publicKey,
      });
      if (data.api_key && data.api_type) {
        setToken(data.api_key, data.api_type);
        updateIsAuth(true);
        navigate(userPaths.contests);
      } else {
        removeToken();
      }
    } catch (error) {}
    disposeLoading();
  };

  const onLoginAdmin = async (username: string, password: string) => {
    onLoading();
    try {
      const data = await loginAdmin({
        username,
        password,
      });
      if (data.api_key && data.api_type) {
        setToken(data.api_key, data.api_type);
        updateIsAuth(true);
        navigate(userPaths.contests);
      } else {
        removeToken();
      }
    } catch (error) {
      showNotification({
        message: get(error, 'message', ''),
        type: 'error',
        onOk() {
          setLoadingSubmit(false)
        }
      })
    }
    disposeLoading();
  };

  useEffect(() => {
    if (isAuth) {
      let isCorrectUrl = false;
      userPathsArr.every((path) => {
        if (location.pathname.includes(path)) {
          isCorrectUrl = true;
          return false;
        }
        return true;
      });
      if (!isCorrectUrl) {
        navigate(userPaths.contests);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);
  useEffect(() => {
    let isTest = false;
    testPathsArr.every((path) => {
      if (location.pathname.includes(path)) {
        isTest = true;
        return false;
      }
      return true;
    });
    if (!isTest) {
      const token = getToken();
      if (token) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
        if (!authPathsArr.includes(location.pathname)) {
          navigate(authPaths.login);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    isAuth,
    updateIsAuth,
    onLogin,
    onLogout,
    onLoginAdmin,
  };
};
