import axios from "@libs/axios";
import { LoginAdminRequest, LoginAdminResponse } from "./types/Admin";

const prefix = "/admin";
export const loginAdmin = async (params: LoginAdminRequest) => {
  const res = await axios.post<LoginAdminResponse>(
    `${prefix}/auth/login`,
    params
  );
  const data = res.data.data;
  return data;
};
