import Pagination from '@atoms/Pagination'
import BaseTable, { Header, TableRef } from '@atoms/Table'
import { deleteContest, fetchContest } from '@store/contests'
import dayjs from '@libs/dayjs'
import { useEffect, useRef, useState } from 'react'
import { Contest, ContestList, ContestListParams } from '@store/types/Contest'
import { PencilSquare, TrashFill } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { userPaths } from '@routes/index'
import showToast from '@utils/showToast'
import Search from './components/FormSearch'
import { IoMdSettings } from 'react-icons/io'
import ConfirmDialog, { ConfirmDialogRef } from '@components/ConfirmDialog'
import { AiFillDelete } from 'react-icons/ai'
import { useNotification } from '@context/Notification'
import { convertUTCToLocal } from '@utils/convertTimezone'
import { DAYJS_DATE_FORMAT } from '@constants/index'
import { useTranslation } from 'react-i18next'

interface Props {}
const ContestPage: React.FC<Props> = () => {
  const { t } = useTranslation()
  const translation = (key: string) => {
    const name = 'Contests'
    return t(`${name}.${key}`)
  }
  const baseTableRef = useRef<TableRef>(null)
  const confirmRef = useRef<ConfirmDialogRef>(null)
  const { showNotification } = useNotification()

  const headers: Header[] = [
    {
      label: translation('NAME'),
      key: 'name',
      render: (value: Contest) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={value.name}
          style={{ width: '100%', maxWidth: 150 }}
        >
          {value.name}
        </div>
      )
    },
    {
      label: translation('DESCRIPTION'),
      key: 'description',
      render: (value: Contest) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={value.description}
          style={{ width: '100%', maxWidth: 150 }}
        >
          {value.description}
        </div>
      )
    },
    {
      label: translation('PHASE_1_START_DATE'),
      key: 'phase_1_start_date',
      render: (value: Contest) => convertUTCToLocal(value.phase_1_start_date, DAYJS_DATE_FORMAT)
    },
    {
      label: translation('PHASE_1_END_DATE'),
      key: 'phase_1_end_date',
      render: (value: Contest) => convertUTCToLocal(value.phase_1_end_date, DAYJS_DATE_FORMAT)
    },
    {
      label: translation('PHASE_2_START_DATE'),
      key: 'phase_2_start_date',
      render: (value: Contest) => convertUTCToLocal(value.phase_2_start_date, DAYJS_DATE_FORMAT)
    },
    {
      label: translation('PHASE_2_END_DATE'),
      key: 'phase_2_end_date',
      render: (value: Contest) => convertUTCToLocal(value.phase_2_end_date, DAYJS_DATE_FORMAT)
    },
    {
      label: translation('PHASE_3_START_DATE'),
      key: 'phase_3_start_date',
      render: (value: Contest) => convertUTCToLocal(value.phase_3_start_date, DAYJS_DATE_FORMAT)
    },
    {
      label: translation('PHASE_3_END_DATE'),
      key: 'phase_3_end_date',
      render: (value: Contest) => convertUTCToLocal(value.phase_3_end_date, DAYJS_DATE_FORMAT)
    },
    {
      label: translation('REMAIN_TIME'),
      key: 'remain_time'
    },
    {
      label: translation('ACTIONS'),
      key: 'action_key',
      width: '15% ',
      render: (value: Contest) => (
        <div className='flex flex-row gap-2'>
          <Button
            onClick={() => {
              if (!isDisabledActions(value)) {
                navigate(`${userPaths.rewards}/${value.id}`)
              } else {
                navigate(`${userPaths.rewards}/view/${value.id}`)
              }
            }}
          >
            <IoMdSettings />
          </Button>
          <Button
            onClick={() => navigate(`${userPaths?.contests}/edit/${value.id}`)}
            disabled={isDisabledActions(value)}
          >
            <PencilSquare />
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              confirmRef.current?.setOpen(true)
              if (confirmRef.current?.onOk) {
                confirmRef.current.onOk(() => {
                  onDeleteContest(value.id)
                })
              }
            }}
            disabled={isDisabledActions(value)}
          >
            <TrashFill />
          </Button>
        </div>
      ),
      isDisabledClick: true
    }
  ]
  const [dataContest, setDataContest] = useState<ContestList>()
  const [contestsParams, setContestsParams] = useState<ContestListParams>({
    page: 1,
    page_size: 100
  })

  const fetchContestAPI = async () => {
    try {
      baseTableRef?.current?.setLoading(true)
      const data = await fetchContest(contestsParams)
      setDataContest({ data })
    } catch (error: any) {
      showToast('error', `Message : ${error?.message as string}` ?? 'Message : Error Unknown')
    } finally {
      baseTableRef?.current?.setLoading(false)
    }
  }
  const onPageChange = (value: string | number) => {
    setContestsParams({
      ...contestsParams,
      page: Number(value)
    })
  }
  const onPageSizeChange = (value: string | number) => {
    setContestsParams({
      ...contestsParams,
      page_size: Number(value) || 1
    })
  }
  const onSearch = (value: string) => {
    setContestsParams({
      ...contestsParams,
      key_search: value,
      page: 1
    })
  }

  const onDeleteContest = async (id: number) => {
    try {
      confirmRef.current?.setLoading(true)
      await deleteContest(id)
      await fetchContestAPI()
      confirmRef.current?.setLoading(false)
      confirmRef.current?.setOpen(false)
      showNotification({
        message: translation('DELETE_CONTEST_SUCCESSFULLY'),
        type: 'success'
      })
    } catch (error: any) {
      showNotification({
        message: `${(error?.message as string) ?? 'Error Unknown'}`,
        type: 'error',
        onOk() {
          confirmRef.current?.setLoading(false)
        }
      })
    }
  }

  const isDisabledActions = (contest: Contest) => {
    const localStartDate = convertUTCToLocal(contest.phase_1_start_date, DAYJS_DATE_FORMAT)
    return dayjs().isAfter(dayjs(localStartDate))
  }
  useEffect(() => {
    fetchContestAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contestsParams.page, contestsParams.page_size, contestsParams.key_search])

  const navigate = useNavigate()
  return (
    <div className='w-full h-full flex flex-col gap-3'>
      <div>
        <div>
          <span className='font-bold text-2xl'>{t('CONTEST')}</span>
        </div>
        <div className='flex flex-row justify-between items-center mt-3'>
          <Search onSearch={onSearch} />
          <Button variant='primary' onClick={() => navigate(`${userPaths?.contests}/create`)}>
            {translation('CREATE_CONTEST')}
          </Button>
        </div>
      </div>
      <div className='flex-1 h-[50%]'>
        <BaseTable
          headers={headers}
          itemsRow={dataContest?.data?.result}
          onClickItem={(value: Contest) => {
            navigate(`${userPaths.contests}/${value.id}`)
          }}
          ref={baseTableRef}
          isTransValue
        />
      </div>
      <div className='self-center'>
        <Pagination
          onPageChange={onPageChange}
          onChangePageSize={onPageSizeChange}
          totalCount={dataContest?.data?.total || 0}
          currentPage={contestsParams.page}
          siblingCount={1}
          pageSize={contestsParams.page_size}
        />
      </div>
      <ConfirmDialog ref={confirmRef} cancelText={t('NO_CANCEL')} okText={t('YES_I_AM_SURE')}>
        <div className='flex flex-col items-center gap-2'>
          <AiFillDelete className='text-[4rem] text-gray-400' />
          <p className='mb-4'>{t('CONFIRM_DELETE')}</p>
        </div>
      </ConfirmDialog>
    </div>
  )
}
export default ContestPage
