import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { EN } from '@trans/en'
import { JA } from '@trans/ja'
const DEFAULT_LANGUAGE = 'ja'
const language = localStorage.getItem('language') || DEFAULT_LANGUAGE

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          ...EN
        }
      },
      ja: {
        translation: {
          ...JA
        }
      }
    },
    lng: language,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng)
})
export default i18n
