import { toast, ToastOptions } from 'react-toastify'

const showToast = (type: 'success' | 'error', message: string, options?: ToastOptions): void => {
  const optionsCustom: ToastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    ...options
  }

  toast[type](message, optionsCustom)
}
export default showToast
