import { ChangeEvent, useState } from 'react'

interface Props {
  type?: string
  label?: string
  placeholder?: string
  width?: string
  state?: string
  onChangeState?: (state: string) => void
}

const BaseInputText: React.FC<Props> = (props) => {
  const { label, placeholder, width, state, onChangeState, type } = props
  const [text, setText] = useState(state)
  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
    onChangeState && onChangeState(e.target.value)
  }
  return (
    <div
      className='flex flex-col gap-3'
      style={{
        width: width || '320px'
      }}
    >
      {label && <span>{label}</span>}
      <input
        type={type || 'text'}
        className={`w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5`}
        placeholder={placeholder}
        value={text}
        onChange={onChangeText}
      />
    </div>
  )
}
export default BaseInputText
