/* eslint-disable */
import BaseInputText from '@atoms/InputText'
import { useAuthentication } from '@hooks/useAuthentication'
import { Route, Routes } from 'react-router-dom'
import Layouts from '..'
import HomePage from '@pages/Home'
import AdminPage from '@pages/Admin'
import EditInfoAdminPage from '@pages/Admin/EditInfo'
import UserPage from '@pages/User'
import ItemsPage from '@pages/Items'
// import ContestPage from '@pages/Contest'
import RolesPage from '@pages/Roles'
import { authPaths, userPaths } from '@routes/index'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import DetailPage from '@pages/User/Detail'
import ContestDetailPage from '@pages/Contest/Detail'
import CreateContestPage from '@pages/Contest/Create'
import EditContestPage from '@pages/Contest/Edit'

import Page404 from '@pages/404'
import Reward from '@pages/Rewards'
import News from '@pages/News'
import CreateNews from '@pages/News/Create'
import EditNews from '@pages/News/Edit'
import AppVersion from '@pages/AppVersion'
import CreateAppVersion from '@pages/AppVersion/Create'
import EditAppVersion from '@pages/AppVersion/Edit'
import ContestPage from '@pages/Contest'
import DetailReward from '@pages/Rewards/Detail'
import Notification from '@pages/Notification'
import CreateNotification from '@pages/Notification/Create'
import EditNotification from '@pages/Notification/Edit'
interface Props {}

const Authentication: React.FC<Props> = () => {
  const { isAuth, onLoginAdmin, onLogout } = useAuthentication()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  return (
    <>
      {isAuth ? (
        <Layouts onLogout={onLogout}>
          <Routes>
            <Route path={userPaths.home}>
              <Route index={true} element={<HomePage />} />
            </Route>
            {/* <Route path={userPaths.admin}>
              <Route index={true} element={<AdminPage />} />
              <Route path={'edit'} element={<EditInfoAdminPage />} />
            </Route> */}
            {/* <Route path={userPaths.users}>
              <Route index={true} element={<UserPage />} />
              <Route path={`${userPaths.users}/:id`} element={<DetailPage />} />
            </Route> */}
            {/* <Route path={userPaths.roles}>
              <Route index={true} element={<RolesPage />} />
            </Route> */}
            {/* <Route path={userPaths.items}>
              <Route index={true} element={<ItemsPage />} />
            </Route> */}
            <Route path={userPaths.contests}>
              <Route index={true} element={<ContestPage />} />
              <Route path={`${userPaths.contests}/:id`} element={<ContestDetailPage />} />
              <Route path={`${userPaths.contests}/create`} element={<CreateContestPage />} />
              <Route path={`${userPaths.contests}/edit/:id`} element={<EditContestPage />} />
            </Route>
            <Route path={`${userPaths.rewards}/:id`}>
              <Route index={true} element={<Reward />} />
            </Route>
            <Route path={`${userPaths.rewards}/view/:id`}>
              <Route index={true} element={<DetailReward />} />
            </Route>
            <Route path={userPaths.news}>
              <Route index={true} element={<News />} />
              <Route path={`${userPaths.news}/create`} element={<CreateNews />} />
              <Route path={`${userPaths.news}/:id`} element={<EditNews />} />
            </Route>
            {/* <Route path={userPaths.appVersion}>
              <Route index={true} element={<AppVersion />} />
              <Route path={`${userPaths.appVersion}/create`} element={<CreateAppVersion />} />
              <Route path={`${userPaths.appVersion}/:id`} element={<EditAppVersion />} />
            </Route> */}
            <Route path={`${userPaths.notification}`}>
              <Route index={true} element={<Notification />} />
              <Route path={`${userPaths.notification}/create`} element={<CreateNotification />} />
              <Route path={`${userPaths.notification}/edit/:id`} element={<EditNotification />} />
            </Route>
            <Route path={'*'} element={<Page404 />}></Route>
          </Routes>
        </Layouts>
      ) : (
        <Routes>
          <Route
            path={authPaths.login}
            element={
              <div className='w-screen h-screen flex flex-col items-center justify-center gap-3'>
                <BaseInputText label='Username' state={username} onChangeState={setUsername} />
                <BaseInputText label='Password' type='password' state={password} onChangeState={setPassword} />
                <Button onClick={() => onLoginAdmin(username, password)}>Login</Button>
              </div>
            }
          />
        </Routes>
      )}
    </>
  )
}
export default Authentication
