import axios from 'axios'
import { getToken } from '@utils/localStorage'

// Set config defaults when creating the instance
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`
})

// Alter defaults after instance has been created
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    config.headers['Authorization'] = getToken()
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error.response.data)
  }
)

export default instance
