/* eslint-disable */
import axios from '@libs/axios'
import { RankGroup } from '@pages/Rewards/schema'
const prefix = '/admin'

const getRewardsById = async (id: number) => {
  const { data } = await axios.get(`${prefix}/contest-rewards/${id}`)
  if (data?.errors) {
    if (data?.errors[0]?.code == 404) {
      throw new Error('Rewards configuration not found')
    }
    throw new Error(data?.message ?? 'ERROR UNKNOWN')
  }
  return data
}
const updateRewardsById = async (id: number, dataUpdate: { rank_groups: RankGroup[] }) => {
  const { data } = await axios.put(`${prefix}/contest-rewards/${id}`, dataUpdate)
  if (data.errors) {
    throw data
  }
  return data
}

export { getRewardsById, updateRewardsById }
