import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

interface ContextType {
  translation: (key: string, params?: any) => string
}

export const Context = React.createContext<ContextType | undefined>(undefined)

interface ContextComponentProviderProps {
  children: React.ReactNode | React.ReactElement
}

export const useComponentContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('useComponentContext must be used within a ContextComponentProvider')
  }
  return context
}

export const ContextComponentProvider: React.FC<ContextComponentProviderProps> = ({ children }) => {
  const { t } = useTranslation()
  const translation = (key: string, params?: any) => {
    const name = 'Notification'
    return t(`${name}.${key}`, params ? params : {}) as string
  }

  return <Context.Provider value={{ translation }}>{children}</Context.Provider>
}
