const DAYJS_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const ITEM_TYPE: { value: number; label: string }[] = [
  { value: 1, label: 'Beautiful Aroma (Small)' },
  { value: 2, label: 'Beautiful Aroma (Medium)' },
  { value: 3, label: 'Beautiful Aroma (Large)' },
  { value: 4, label: 'Power Aroma (Small)' },
  { value: 5, label: 'Power Aroma (Medium)' },
  { value: 6, label: 'Power Aroma (Large)' },
  { value: 7, label: 'Agility Aroma (Small)' },
  { value: 8, label: 'Agility Aroma (Medium)' },
  { value: 9, label: 'Agility Aroma (Large)' },
  { value: 10, label: 'Inspiration Aroma (Small)' },
  { value: 11, label: 'Inspiration Aroma (Medium)' },
  { value: 12, label: 'Inspiration Aroma (Large)' }
]

const RARITY: { value: number; label: string }[] = [
  {
    value: 1,
    label: 'Rarity ★ 1'
  },
  {
    value: 2,
    label: 'Rarity ★ 2'
  },
  {
    value: 3,
    label: 'Rarity ★ 3'
  },
  {
    value: 4,
    label: 'Rarity ★ 4'
  },
  {
    value: 5,
    label: 'Rarity ★ 5'
  }
]

export { DAYJS_DATE_FORMAT, ITEM_TYPE, RARITY }
