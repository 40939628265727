import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { Button, Modal } from 'react-bootstrap'

type NotificationType = 'success' | 'error'

type Notification = {
  open: boolean
  message: string
  type: NotificationType
  onOk?: () => void
}

type NotificationContextType = {
  showNotification: (input: { message: string; type: NotificationType; onOk?: () => void }) => void
  hideNotification: (onOk?: () => void) => void
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined)

export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }
  return context
}

type NotificationProviderProps = {
  children: ReactNode
}

export const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const [notification, setNotification] = useState<Notification>({ open: false, message: '', type: 'success' })

  const showNotification = useCallback(
    ({ message, type, onOk }: { message: string; type: NotificationType; onOk?: () => void }) => {
      setNotification({ open: true, message, type, onOk })
    },
    []
  )

  const hideNotification = useCallback(() => {
    setNotification((prevState) => {
      const newState = { ...prevState, open: false }
      if (prevState.onOk && typeof prevState.onOk === 'function' && prevState.onOk !== (() => {})) {
        prevState.onOk()
      }
      return newState
    })
  }, [])

  const handleModalExited = useCallback(() => {
    setNotification({ message: '', open: false, type: 'success', onOk: () => {} })
  }, [])

  // Determine dialog style or additional components based on notification type
  const renderNotificationContent = () => {
    switch (notification.type) {
      case 'success':
        return (
          <>
            <div className='flex flex-col items-center'>
              <BsCheckCircle className='text-[6rem] text-green-500' />
            </div>
            <div className='flex flex-col items-center mt-3'>
              <p className='mb-4 text-center'>{notification.message}</p>
            </div>
            <div className='flex justify-center'>
              <Button onClick={hideNotification} className='min-w-[100px]'>
                OK
              </Button>
            </div>
          </>
        )
      case 'error':
        return (
          <>
            <div className='flex flex-col items-center gap-2'>
              <BsXCircle className='text-[6rem] text-red-400' />
              <p className='mb-4 text-center'>{notification.message}</p>
            </div>
            <div className='flex justify-center'>
              <Button onClick={hideNotification} className='min-w-[100px]'>
                OK
              </Button>
            </div>
          </>
        )
      default:
        return <div className='text-center'>{notification.message}</div>
    }
  }

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      {children}
      <Modal
        show={notification.open}
        onHide={hideNotification}
        backdrop='static'
        keyboard={false}
        centered
        onExited={handleModalExited}
      >
        <div className='p-4'>{renderNotificationContent()}</div>
      </Modal>
    </NotificationContext.Provider>
  )
}
