import { useFieldArray, useFormContext } from 'react-hook-form'
import { BsDashCircle } from 'react-icons/bs'
import { BsPlusCircle } from 'react-icons/bs'
import InputNumber from '@components/Form/InputNumber'
import Select from '@components/Form/Select'
import { useRewardContext } from '@pages/Rewards/context'
import { ErrorMessage } from '@hookform/error-message'
import { Form } from 'react-bootstrap'
import { has } from 'lodash'
import React from 'react'

type Props = {
  index: number
  name: string
  trigger?: () => void
}

const Eggs = ({ index, name, trigger }: Props) => {
  const {
    register,
    control,
    clearErrors,
    formState: { errors }
  } = useFormContext()
  const { rarity, type } = useRewardContext()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: name,
    keyName: '_id'
  })
  const { translation } = useRewardContext()
  return (
    <div>
      <ul>
        {fields.length === 0 ? (
          ''
        ) : (
          <>
            {fields.map((item, k) => {
              return (
                <React.Fragment key={`${item._id}`}>
                  {!has(item, '_destroy') ? (
                    <li className='mb-2'>
                      <div className='flex gap-2'>
                        <div className='col-3'>
                          <InputNumber
                            register={register(`${name}.${k}.amount`, { valueAsNumber: true })}
                            label=''
                            showLabel={false}
                            showError={false}
                          />
                        </div>
                        <div className='col-4'>
                          <Select
                            options={rarity}
                            control={control}
                            showLabel={false}
                            name={`${name}.${k}.rarity_id`}
                            label={`${name}.${k}.rarity_id`}
                            callbackOnChange={() => {
                              if (trigger) {
                                trigger()
                              }
                            }}
                          />
                        </div>
                        <div className='col-4'>
                          <Select
                            options={type}
                            control={control}
                            showLabel={false}
                            name={`${name}.${k}.talent_id`}
                            label={`${name}.${k}.talent_id`}
                            callbackOnChange={() => {
                              if (trigger) {
                                trigger()
                              }
                            }}
                          />
                        </div>
                        <div className='flex items-center col-1'>
                          <button
                            type='button'
                            className='cursor-pointer'
                            onClick={() => {
                              if (has(item, 'id')) {
                                update(k, { ...item, _destroy: '1' })
                                clearErrors(`${name}.${k}`)
                              } else {
                                remove(k)
                              }
                              // if (trigger) {
                              //   trigger()
                              // }
                            }}
                          >
                            <BsDashCircle />
                          </button>
                        </div>
                      </div>
                      <div>
                        <ErrorMessage
                          errors={errors}
                          name={`${name}.${k}.amount`}
                          render={({ message }) => (
                            <Form.Text className='text-danger mt-2 block'>
                              {message
                                ? translation(message, {
                                    name: translation('EGGS'),
                                    nameToLowCase: translation('EGGS').toLocaleLowerCase()
                                  })
                                : ''}
                            </Form.Text>
                          )}
                        />
                      </div>
                    </li>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              )
            })}
          </>
        )}
      </ul>
      <div className='flex justify-center'>
        <button
          type='button'
          className='cursor-pointer'
          onClick={() => {
            append({ amount: 1, rarity_id: 1, talent_id: 1 })
          }}
        >
          <BsPlusCircle />
        </button>
      </div>
    </div>
  )
}

export default Eggs
