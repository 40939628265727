import { Card, Form } from 'react-bootstrap'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Button from '@atoms/Button'
import FormReward from './components/Form'
import { ContestRewards, RankGroup } from '@pages/Rewards/schema'
import { BsDashCircle, BsPlusCircle } from 'react-icons/bs'
import { get, has } from 'lodash'
import React, { useCallback } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { useRewardContext } from '@pages/Rewards/context'
import { useTranslation } from 'react-i18next'

type ClassRewardProps = {
  index: number
  updateRewards: (data: RankGroup) => void
  closeRewards: () => void
  loading: boolean
}
const defaultReward = {
  highest_place: 1,
  lowest_place: 1,
  spt_amount: 0,
  gspt_amount: 0,
  contest_rewards_eggs: [],
  contest_rewards_items: []
}
const ClassReward = ({ index, updateRewards, closeRewards, loading }: ClassRewardProps) => {
  const name = `rank_groups.[${index}].contest_rewards`

  const { translation } = useRewardContext()
  const { t } = useTranslation()

  const {
    control,
    getValues,
    formState: { errors, isDirty },
    trigger,
    clearErrors
  } = useFormContext()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: name,
    keyName: '_id'
  })

  const onSave = useCallback(() => {
    const values = getValues(`rank_groups.[${index}]`)

    trigger(`rank_groups?.${index}`).then((isValid) => {
      if (isValid) {
        updateRewards(values)
      }
    })
    // eslint-disable-next-line
  }, [trigger, getValues, updateRewards, index, name])

  const onCancel = useCallback(() => {
    closeRewards()
  }, [closeRewards])

  const lengthArrayFiledActive = useCallback(() => {
    return fields.filter((item) => !has(item, '_destroy')).length
  }, [fields])

  const renderRewardForm = (rewards: ContestRewards, index: number) => {
    return (
      <Card className='p-4 my-3'>
        <div className='flex items-center justify-between mb-3'>
          <div className='text-lg font-medium'>Set reward</div>
          <div>
            <button
              type='button'
              className='cursor-pointer text-[1rem]'
              title='remove reward'
              onClick={() => {
                if (has(rewards, 'id')) {
                  update(index, { ...rewards, _destroy: '1' })
                  clearErrors(`${name}[${index}]`)
                } else {
                  remove(index)
                }
              }}
            >
              <BsDashCircle />
            </button>
          </div>
        </div>
        <FormReward index={index} name={name} />
      </Card>
    )
  }
  return (
    <div>
      {fields.map((item, index) => {
        return (
          <React.Fragment key={item._id}>
            {!has(item, '_destroy') ? <div>{renderRewardForm(item as ContestRewards, index)}</div> : <></>}
          </React.Fragment>
        )
      })}
      <div className='flex justify-center mt-2'>
        <button
          type='button'
          className='cursor-pointer text-[1rem]'
          title='add reward'
          onClick={() => {
            append(defaultReward)
          }}
        >
          <BsPlusCircle />
        </button>
      </div>

      <div className='flex justify-center'>
        <ErrorMessage
          errors={errors}
          name={`${name}`}
          render={({ message }) => (
            <Form.Text className='text-danger mt-2 block'>{message ? translation(message) : ''}</Form.Text>
          )}
        />
      </div>

      <div className='flex justify-end mt-3 gap-3'>
        <Button
          className='mb-3 min-w-[80px]'
          type='button'
          disabled={!!get(errors, name) || !(lengthArrayFiledActive() > 0) || !isDirty || loading}
          loading={loading}
          onClick={onSave}
        >
          {t('SAVE')}
        </Button>
        <Button className='mb-3 btn-danger min-w-[80px] capitalize' type='button' onClick={onCancel}>
          {t('CANCEL')}
        </Button>
      </div>
    </div>
  )
}

export default ClassReward
