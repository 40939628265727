import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import LoadingComponent from '@components/Loading/loading-component'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export interface Header {
  label: string
  key: string
  width?: string
  render?: (value: any) => any
  isDisabledClick?: boolean
  align?: 'left' | 'center' | 'right'
}

interface BaseTableProps {
  headers?: Header[]
  itemsRow?: any[]
  isTransValue?: boolean
  onClickItem?: (value: any) => void
}
export interface TableRef {
  setLoading: (isLoading: boolean) => void
}
const ContainerTable = styled.div`
  .table-responsive-lg {
    height: 100%;
  }
`
const TableStyled = styled(Table)`
  z-index: 1;
  &.table > thead > tr > th {
    color: rgb(55 65 81 /1);
    font-size: 0.875rem;
    line-height: 1.25rem;
    vertical-align: middle;
    background-color: rgb(249 250 251 /1);
  }
  &.table thead {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    z-index: 2;
  }
  &.table tbody {
    vertical-align: middle;
  }
`

const BaseTable = forwardRef<TableRef, BaseTableProps>((props, ref) => {
  const { headers, itemsRow, isTransValue, onClickItem } = props
  const [loading, setLoading] = useState<boolean>(false)
  const percentage = headers?.length ? 100 / headers.length : 100
  const { t } = useTranslation()
  useImperativeHandle(ref, () => ({
    setLoading(isLoading) {
      setLoading(isLoading)
    }
  }))

  const transStrInValue = (val: string) => {
    return val.replace('day', t('day'.toUpperCase()));
  }
  return (
    <>
      <div className='relative w-full h-full'>
        {loading ? <LoadingComponent className='z-[3]' /> : ''}
        <ContainerTable className={classNames('w-full h-full grow overflow-auto')}>
          <TableStyled responsive='sm' hover={!!onClickItem}>
            <thead className='text-sm uppercase'>
              <tr>
                {headers?.map((header) => (
                  <th
                    className={`px-6 py-3 `}
                    key={`header_${header.label}`}
                    style={{
                      width: `${header.width || percentage}%`,
                      textAlign: `${header.align ?? 'left'}`
                    }}
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {itemsRow && itemsRow?.length > 0 ? (
                <>
                  {itemsRow?.map((item, index) => (
                    <tr className={`${onClickItem ? 'hover:cursor-pointer' : ''}`} key={index}>
                      {headers?.map((header, index) => (
                        <td
                          className={`px-6 py-3`}
                          key={index}
                          style={{
                            width: `${header.width || percentage}%`,
                            maxWidth: `${header.width || percentage}%`,
                            textAlign: `${header.align ?? 'left'}`
                          }}
                          onClick={() => onClickItem && !header.isDisabledClick && onClickItem(item)}
                        >
                          {isTransValue && header.key === 'remain_time'
                            ? transStrInValue(item.remain_time)
                            : header.render ? header.render(item) : item[header.key]
                          }
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={headers?.length} className='text-center py-4'>
                    {t('NO_DATA')}
                  </td>
                </tr>
              )}
            </tbody>
          </TableStyled>
        </ContainerTable>
      </div>
    </>
  )
})
export default BaseTable
