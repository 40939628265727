import React from 'react'
import { Form } from 'react-bootstrap'
import { ErrorMessage } from '@hookform/error-message'
import { useTranslation } from 'react-i18next'
type InputProps = {
  label: string
  placeholder?: string
  register?: any
  errors?: { [x: string]: any }
  showLabel?: boolean
  showError?: boolean
  t?: (key: string) => string
}

const Textarea: React.FC<InputProps> = ({
  label,
  placeholder = '',
  register,
  showLabel = true,
  errors,
  showError = true,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Form.Group controlId={`form-${(register?.name ?? '').toLowerCase()}`} className='w-full'>
      {showLabel ? <Form.Label className='text-base capitalize'>{label}</Form.Label> : ''}
      <Form.Control as='textarea' rows={3} placeholder={placeholder} {...register} />
      {showError && errors ? (
        <ErrorMessage
          errors={errors}
          name={register?.name ?? ''}
          render={({ message }) => (
            <Form.Text className='text-danger mt-2 block'>{rest?.t ? rest.t(message) : t(message)}</Form.Text>
          )}
        />
      ) : (
        <></>
      )}
    </Form.Group>
  )
}
export default Textarea
