import { userPaths } from '@routes/index'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

interface ErrorProps {
  errorMessage: string
  clearError: () => void
  type?: 'error' | '404' | '401'
  path?: string
}

const Error: React.FC<ErrorProps> = ({ errorMessage, clearError, type = 'error', path = userPaths?.contests }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className='grid h-screen place-content-center bg-white px-4'>
      <div className='text-center'>
        <h1 className='text-9xl font-black text-gray-200 uppercase'>{type}</h1>
        <p className='text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl'>Oh no!</p>
        <p className='mt-4 text-gray-500'>{errorMessage}</p>
        <Link
          to={''}
          className='mt-4 inline-block capitalize rounded bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600 no-underline'
          onClick={(e) => {
            e.preventDefault()
            clearError()
            navigate(path as string)
          }}
        >
          {t('GO_BACK')}
        </Link>
      </div>
    </div>
  )
}

export default Error
