import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal } from 'react-bootstrap'

type DialogProp = {
  children?: React.ReactElement | React.ReactNode
  open?: boolean
  closeModal?: boolean
}

export type DialogRef = {
  setOpen: (isOpen: boolean) => void
}

const Dialog = forwardRef<DialogRef, DialogProp>(({ children, closeModal, ...rest }: DialogProp, ref) => {
  const [open, setIsOpen] = useState<boolean>(rest.open === true ? true : false)

  useImperativeHandle(ref, () => ({
    setOpen(isOpen) {
      setIsOpen(isOpen)
    }
  }))

  return (
    <Modal show={open} backdrop='static' keyboard={false} centered>
      <div className='p-4 relative'>
        {closeModal ?? (
          <button
            type='button'
            className='text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:opacity-80 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
            data-modal-toggle='deleteModal'
            onClick={() => {
              setIsOpen(false)
            }}
          >
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
            <span className='sr-only'>Close modal</span>
          </button>
        )}

        <div>{children}</div>
      </div>
    </Modal>
  )
})

export default Dialog
