
import en from './en.json'
import ja from './ja.json'

const translations = {
  en,
  ja
}

export default translations
