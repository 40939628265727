import { useErrorContext } from '@context/Error'
import { useNotification } from '@context/Notification'
import { fetchContestDetail, updateContest } from '@store/contests'
import { Contest } from '@store/types/Contest'
import { SheepnParam } from '@store/types/SheepnParam'
import { Talent } from '@store/types/Talent'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { TUpdateContest, UpdateContestSchema } from '../schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { fetchTalents } from '@store/talents'
import { fetchSheepnParams } from '@store/sheepnParams'
import { userPaths } from '@routes/index'
import Button from '@atoms/Button'
import { Form, Button as ButtonBootstrap } from 'react-bootstrap'
import dayjs from 'dayjs'
import { DAYJS_DATE_FORMAT } from '@constants/index'
import BaseDatePicker from '@atoms/DatePicker'
import InputNumber from '@components/Form/InputNumber'
import BaseMultiSelect from '@atoms/MultiSelect'
import Input from '@components/Form/Input'
import { useLoading } from '@layouts/Loading'
import { convertUTCToLocal, revertToUTC } from '@utils/convertTimezone'
import { useTranslation } from 'react-i18next'

const EditContest = () => {
  const { t } = useTranslation()
  const translation = (key: string, option?: any) => {
    const name = 'Contests'
    return t(`${name}.${key}`, option ?? {}) as string
  }
  const errorContext = useErrorContext()
  const [talents, setTalents] = useState<Talent[]>([])
  const [sheepnParams, setSheepnParams] = useState<SheepnParam[]>([])
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [startDate1, setStartDate1] = useState<dayjs.Dayjs>(dayjs().add(1, 'day'));
  const [endDate1, setEndDate1] = useState<dayjs.Dayjs>(dayjs().add(1, 'day').add(23, 'hours'));
  const [startDate2, setStartDate2] = useState<dayjs.Dayjs>(dayjs().add(2, 'day'));
  const [endDate2, setEndDate2] = useState<dayjs.Dayjs>(dayjs().add(2, 'day').add(23, 'hours'));
  const [startDate3, setStartDate3] = useState<dayjs.Dayjs>(dayjs().add(3, 'day'));
  const [endDate3, setEndDate3] = useState<dayjs.Dayjs>(dayjs().add(3, 'day').add(23, 'hours'));
  const [fieldFocus, setFieldFocus] = useState<string>('')
  const { showNotification } = useNotification()
  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid }
  } = useForm<TUpdateContest>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(UpdateContestSchema)
  })

  const { disposeLoading, onLoading } = useLoading()

  const setFormValues = (values: Partial<Contest>) => {
    ; (Object.keys(values) as Array<keyof Contest>).forEach((key) => {
      setValue(key as any, values[key])
    })
  }
  const params = useParams()
  const fetchApiContestDetail = useCallback(
    async (id: number) => {
      try {
        onLoading()
        const contestDetail: Contest = await fetchContestDetail(id)
        const valueConvertToTimeLocalL: Contest = {
          ...contestDetail,
          phase_1_start_date: convertUTCToLocal(contestDetail.phase_1_start_date),
          phase_1_end_date: convertUTCToLocal(contestDetail.phase_1_end_date),
          phase_2_start_date: convertUTCToLocal(contestDetail.phase_2_start_date),
          phase_2_end_date: convertUTCToLocal(contestDetail.phase_2_end_date),
          phase_3_start_date: convertUTCToLocal(contestDetail.phase_3_start_date),
          phase_3_end_date: convertUTCToLocal(contestDetail.phase_3_end_date)
        }
        setFormValues(valueConvertToTimeLocalL)
      } catch (error) {
        errorContext.setError(new Error(error as string))
      } finally {
        disposeLoading()
      }
    },
    // eslint-disable-next-line
    [errorContext, onLoading, disposeLoading]
  )

  const navigate = useNavigate()
  const getTalents = async () => {
    const data = await fetchTalents()
    setTalents(data?.result || [])
  }
  const getSheepnParams = async () => {
    const data = await fetchSheepnParams()
    setSheepnParams(data?.result || [])
  }

  const getTalentSelected = (talentIds: number[]) => {
    return talentIds?.map((id: number) => ({
      label: talents.find((elem) => elem.id === id)?.name,
      value: id?.toString()
    }))
  }
  const getSheepnParamsSelected = (sheepnParamIds: number[]) => {
    return sheepnParamIds?.map((id: number) => ({
      label: sheepnParams.find((elem) => elem.id === id)?.name,
      value: id?.toString()
    }))
  }
  useEffect(() => {
    getTalents()
    getSheepnParams()
  }, [])

  const onSubmit = async (values: TUpdateContest) => {
    try {
      setLoadingSubmit(true)
      const valueRevertDateUTC: TUpdateContest = {
        ...values,
        phase_1_start_date: revertToUTC(values.phase_1_start_date),
        phase_1_end_date: revertToUTC(values.phase_1_end_date),
        phase_2_start_date: revertToUTC(values.phase_2_start_date),
        phase_2_end_date: revertToUTC(values.phase_2_end_date),
        phase_3_start_date: revertToUTC(values.phase_3_start_date),
        phase_3_end_date: revertToUTC(values.phase_3_end_date)
      }
      const data = await updateContest({ ...valueRevertDateUTC, id: parseInt(params?.id as string) })
      if (data?.errors) {
        throw data.errors
      }
      showNotification({
        message: translation('UPDATE_CONTEST_SUCCESSFULLY'),
        type: 'success',
        onOk() {
          navigate(`${userPaths?.contests}`)
        }
      })
    } catch (errors: any) {
      let content = ''
      if (errors?.length > 0) {
        content = `${errors?.map((error: any) => error?.message).join(', ')}`
      }
      showNotification({
        message: `${content}`,
        type: 'error',
        onOk() {
          setLoadingSubmit(false)
        }
      })
    } finally {
      setLoadingSubmit(false)
    }
  }

  useEffect(() => {
    fetchApiContestDetail(params?.id as unknown as number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])
  return (
    <div className='w-full h-full flex flex-col gap-3'>
      <h1 className='font-bold text-2xl mb-4'>{translation('EDIT_CONTEST')}</h1>
      <Form className='w-full flex flex-col gap-3' onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>
          <div className='col-3'>
            <Form.Group controlId={`form-name`} className='w-full'>
              <Input
                label={translation('NAME')}
                errors={errors}
                register={register('name')}
                placeholder={translation('ENTER_INPUT', { name: translation('NAME')?.toLowerCase() })}
                t={(key: string) =>
                  translation(key, {
                    name: translation('NAME'),
                    nameToLowCase: translation('NAME').toLocaleLowerCase()
                  })
                }
              />
            </Form.Group>
          </div>
          <div className='col-3'>
            <Form.Group controlId={`form-description`} className='w-full'>
              <Input
                label={translation('DESCRIPTION')}
                errors={errors}
                register={register('description')}
                placeholder={translation('ENTER_INPUT', {
                  name: translation('DESCRIPTION')?.toLowerCase()
                })}
                t={(key: string) =>
                  translation(key, {
                    name: translation('DESCRIPTION'),
                    nameToLowCase: translation('DESCRIPTION').toLocaleLowerCase()
                  })
                }
              />
            </Form.Group>
          </div>
          <div className='col-3'>
            <Form.Group controlId='form-bonus_parameter' className='mb-3 w-full'>
              <Form.Label className='text-base'>{translation('SHEEPN_IDS')}</Form.Label>
              <Controller
                control={control}
                name='sheepn_param_ids'
                render={({ field: { onChange, value } }) => {
                  return (
                    <BaseMultiSelect
                      width='100%'
                      onChange={(state) => {
                        onChange(state.map((elem) => Number(elem.value)))
                        trigger('sheepn_param_ids')
                      }}
                      options={sheepnParams.map((elem: SheepnParam) => ({
                        label: elem.name,
                        value: elem.id.toString()
                      }))}
                      value={getSheepnParamsSelected(value)}
                    />
                  )
                }}
              />
              {errors.sheepn_param_ids && (
                <Form.Text className='text-danger'>
                  {translation(errors.sheepn_param_ids.message as string, {
                    nameToLowCase: translation('SHEEPN_IDS').toLowerCase(),
                    name: translation('SHEEPN_IDS')
                  })}
                  {/* {errors.sheepn_param_ids.message} */}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <div className='col-3'>
            <Form.Group controlId='form-bonus_parameter' className='mb-3 w-full'>
              <Form.Label className='text-base'>{translation('TALENT_IDS')}</Form.Label>
              <Controller
                control={control}
                name='talent_ids'
                render={({ field: { onChange, value } }) => {
                  return (
                    <BaseMultiSelect
                      width='100%'
                      onChange={(state) => {
                        onChange(state.map((elem) => Number(elem.value)))
                        trigger('talent_ids')
                      }}
                      options={talents.map((elem: SheepnParam) => ({
                        label: elem.name,
                        value: elem.id.toString()
                      }))}
                      value={getTalentSelected(value)}
                    />
                  )
                }}
              />
              {errors.talent_ids && (
                <Form.Text className='text-danger'>
                  {translation(errors.talent_ids.message as string, {
                    nameToLowCase: translation('TALENT_IDS').toLowerCase(),
                    name: translation('TALENT_IDS')
                  })}
                </Form.Text>
              )}
            </Form.Group>
          </div>
        </div>
        <div className='row'>
          <div className='col-3'>
            <Form.Group controlId='form-bonus_parameter' className='mb-3 w-full'>
              <InputNumber
                label={translation('BONUS_PARAMETER')}
                errors={errors}
                placeholder={translation('ENTER_INPUT', { name: translation('BONUS_PARAMETER')?.toLowerCase() })}
                register={register('bonus_parameter', { valueAsNumber: true })}
                t={(key: string) =>
                  translation(key, {
                    name: translation('BONUS_PARAMETER'),
                    nameToLowCase: translation('BONUS_PARAMETER').toLocaleLowerCase()
                  })
                }
              />
            </Form.Group>
          </div>
          <div className='col-3'>
            <InputNumber
              label={translation('BONUS_TALENT')}
              errors={errors}
              placeholder={translation('ENTER_INPUT', { name: translation('BONUS_TALENT')?.toLowerCase() })}
              register={register('bonus_talent', { valueAsNumber: true })}
              t={(key: string) =>
                translation(key, {
                  name: translation('BONUS_TALENT'),
                  nameToLowCase: translation('BONUS_TALENT').toLocaleLowerCase()
                })
              }
            />
          </div>
          <div className='col-3'>
            <InputNumber
              label={translation('MAXIMUM_INCENTIVE_BONUS')}
              errors={errors}
              placeholder={translation('ENTER_INPUT', { name: translation('MAXIMUM_INCENTIVE_BONUS')?.toLowerCase() })}
              register={register('maximum_incentive_bonus', { valueAsNumber: true })}
              t={(key: string) =>
                translation(key, {
                  name: translation('MAXIMUM_INCENTIVE_BONUS'),
                  nameToLowCase: translation('MAXIMUM_INCENTIVE_BONUS').toLocaleLowerCase()
                })
              }
            />
          </div>
          <div className='col-3'>
            <InputNumber
              label={translation('MAXIMUM_INCENTIVE_STAR')}
              errors={errors}
              placeholder={translation('ENTER_INPUT', { name: translation('MAXIMUM_INCENTIVE_STAR')?.toLowerCase() })}
              register={register('maximum_incentive_star', { valueAsNumber: true })}
              t={(key: string) =>
                translation(key, {
                  name: translation('MAXIMUM_INCENTIVE_STAR'),
                  nameToLowCase: translation('MAXIMUM_INCENTIVE_STAR').toLocaleLowerCase()
                })
              }
            />
          </div>
        </div>
        <div className='flex flex-row border'>
          <div className='flex flex-col w-1/3 items-center p-3 gap-3 border-r'>
            <Controller
              control={control}
              name='phase_1_start_date'
              render={({ field: { onChange, value } }) => {
                return (
                  <BaseDatePicker
                    label={translation('PHASE_1_START_DATE')}
                    minDate={dayjs().toDate()}
                    selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
                    onChangeSelectedDate={(newDate: Date | null) => {
                      setStartDate1(dayjs(newDate))
                      onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
                      setFieldFocus('phase_1_start_date')
                    }}
                  />
                )
              }}
            />
            {fieldFocus === 'phase_1_start_date' && startDate1?.isAfter(endDate1) && (
              <Form.Text className='text-danger text-left w-full'>{translation('VALID_START_DATETIME_BEFORE_END_DATETIME')}</Form.Text>
            )}
            <Controller
              control={control}
              name='phase_1_end_date'
              render={({ field: { onChange, value } }) => {
                return (
                  <BaseDatePicker
                    label={translation('PHASE_1_END_DATE')}
                    minDate={dayjs().toDate()}
                    selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
                    onChangeSelectedDate={(newDate: Date | null) => {
                      setEndDate1(dayjs(newDate))
                      onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
                      setFieldFocus('phase_1_end_date')
                    }}
                  />
                )
              }}
            />
            {fieldFocus === 'phase_1_end_date' && endDate1?.isBefore(startDate1) && (
              <Form.Text className='text-danger text-left w-full'>{translation('VALID_END_DATETIME_AFTER_END_DATETIME')}</Form.Text>
            )}
          </div>
          <div className='flex flex-col w-1/3 items-center p-3 gap-3'>
            <Controller
              control={control}
              name='phase_2_start_date'
              render={({ field: { onChange, value } }) => {
                return (
                  <BaseDatePicker
                    label={translation('PHASE_2_START_DATE')}
                    minDate={dayjs().toDate()}
                    selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
                    onChangeSelectedDate={(newDate: Date | null) => {
                      setStartDate2(dayjs(newDate))
                      onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
                      setFieldFocus('phase_2_start_date')
                    }}
                  />
                )
              }}
            />
            {fieldFocus === 'phase_2_start_date' && startDate2?.isAfter(endDate2) && (
              <Form.Text className='text-danger text-left w-full'>{translation('VALID_START_DATETIME_BEFORE_END_DATETIME')}</Form.Text>
            )}
            {fieldFocus === 'phase_2_start_date' && startDate2?.isBefore(endDate1) && (
              <Form.Text className='text-danger text-left w-full'>{translation('VALID_OVERLAP_OTHER_PHASES')}</Form.Text>
            )}
            <Controller
              control={control}
              name='phase_2_end_date'
              render={({ field: { onChange, value } }) => {
                return (
                  <BaseDatePicker
                    label={translation('PHASE_2_END_DATE')}
                    minDate={dayjs().toDate()}
                    selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
                    onChangeSelectedDate={(newDate: Date | null) => {
                      setEndDate2(dayjs(newDate))
                      onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
                      setFieldFocus('phase_2_end_date')
                    }}
                  />
                )
              }}
            />
            {fieldFocus === 'phase_2_end_date' && endDate2?.isBefore(startDate2) && (
              <Form.Text className='text-danger text-left w-full'>{translation('VALID_END_DATETIME_AFTER_END_DATETIME')}</Form.Text>
            )}
          </div>
          <div className='flex flex-col w-1/3 items-center p-3 gap-3 border-l'>
            <Controller
              control={control}
              name='phase_3_start_date'
              render={({ field: { onChange, value } }) => {
                return (
                  <BaseDatePicker
                    label={translation('PHASE_3_START_DATE')}
                    minDate={dayjs().toDate()}
                    selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
                    onChangeSelectedDate={(newDate: Date | null) => {
                      setStartDate3(dayjs(newDate))
                      onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
                      setFieldFocus('phase_3_start_date')
                    }}
                  />
                )
              }}
            />
            {fieldFocus === 'phase_3_start_date' && startDate3?.isAfter(endDate3) && (
              <Form.Text className='text-danger text-left w-full'>{translation('VALID_START_DATETIME_BEFORE_END_DATETIME')}</Form.Text>
            )}
            {fieldFocus === 'phase_3_start_date' && startDate3?.isBefore(endDate2) && (
              <Form.Text className='text-danger text-left w-full'>{translation('VALID_OVERLAP_OTHER_PHASES')}</Form.Text>
            )}
            <Controller
              control={control}
              name='phase_3_end_date'
              render={({ field: { onChange, value } }) => {
                return (
                  <BaseDatePicker
                    label={translation('PHASE_3_END_DATE')}
                    minDate={dayjs().toDate()}
                    selectedDate={new Date(dayjs(value).format(DAYJS_DATE_FORMAT))}
                    onChangeSelectedDate={(newDate: Date | null) => {
                      setEndDate3(dayjs(newDate))
                      onChange(dayjs(newDate).format(DAYJS_DATE_FORMAT))
                      setFieldFocus('phase_3_end_date')
                    }}
                  />
                )
              }}
            />
            {fieldFocus === 'phase_3_end_date' && endDate3?.isBefore(startDate3) && (
              <Form.Text className='text-danger text-left w-full'>{translation('VALID_END_DATETIME_AFTER_END_DATETIME')}</Form.Text>
            )}
          </div>
        </div>
        <div className='flex justify-end mt-5'>
          <div className='flex gap-3'>
            <Button
              type='submit'
              className='min-w-[80px]'
              disabled={
                !isDirty
                || !isValid
                || loadingSubmit
                || startDate1?.isAfter(endDate1)
                || endDate1?.isBefore(startDate1)
                || startDate2?.isAfter(endDate2)
                || startDate2?.isBefore(endDate1)
                || endDate2?.isBefore(startDate2)
                || startDate3?.isAfter(endDate3)
                || startDate3?.isBefore(endDate2)
                || endDate3?.isBefore(startDate3)
              }
              loading={loadingSubmit}
            >
              {t('EDIT')}
            </Button>
            <ButtonBootstrap
              variant='danger'
              disabled={loadingSubmit}
              onClick={() => navigate(`${userPaths?.contests}`)}
              className='min-w-[80px]'
            >
              {t('CLOSE')}
            </ButtonBootstrap>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default EditContest
