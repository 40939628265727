import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import BaseDatePicker from '@atoms/DatePicker'
import { fetchSheepnParams } from '@store/sheepnParams'
import { fetchTalents } from '@store/talents'
import { Contest } from '@store/types/Contest'
import { SheepnParam } from '@store/types/SheepnParam'
import { Talent } from '@store/types/Talent'
import dayjs from 'dayjs'
import GoBackButton from './components/GobackButton'
import showToast from '@utils/showToast'
import { useParams } from 'react-router-dom'
import { fetchContestDetail } from '@store/contests'
import Loading from '@components/Loading'
import { useErrorContext } from '../../../context/Error/index'
import styled from 'styled-components'
import { convertUTCToLocal } from '@utils/convertTimezone'
import { useTranslation } from 'react-i18next'
const DAYJS_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const Container = styled.div`
  .overflow-ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const ContestDetailPage: React.FC = () => {
  const params = useParams()
  const { setError } = useErrorContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [talents, setTalents] = useState<Talent[] | undefined>(undefined)
  const [sheepParams, setSheepnParams] = useState<SheepnParam[] | undefined>(undefined)
  const [data, setData] = useState<Contest | null>(null)
  const { t } = useTranslation()
  const translation = (key: string) => {
    const name = 'Contests'
    return t(`${name}.${key}`)
  }
  const getTalents = async () => {
    const data = await fetchTalents()
    setTalents(data?.result || [])
  }
  const getSheepnParams = async () => {
    const data = await fetchSheepnParams()
    setSheepnParams(data?.result || [])
  }
  const getLabelSheepParams = (arraySheepId: number[], arraySheepParams?: SheepnParam[]) => {
    if (arraySheepId?.length <= 0) {
      return <>No value has been set</>
    }
    return arraySheepId?.map((sheepId) => {
      let sheepParam = arraySheepParams?.find((item) => {
        return item.id === sheepId
      })
      return (
        <span className='bg-gray-300 text-base me-2 px-2.5 py-1 rounded whitespace-nowrap' key={`talents_${sheepId}`}>
          {sheepParam
            ? `${
                arraySheepParams?.find((item) => {
                  return item.id === sheepId
                })?.name
              }`
            : sheepId}
        </span>
      )
    })
  }
  const getLabelTalent = (arrayTalentIds: number[], talents?: Talent[]) => {
    if (arrayTalentIds?.length <= 0) {
      return <>No value has been set</>
    }
    return arrayTalentIds?.map((talentId) => {
      let talent = talents?.find((item) => {
        return item.id === talentId
      })
      return (
        <span className='bg-gray-300 text-base me-2 px-2.5 py-1 rounded whitespace-nowrap' key={`talents_${talentId}`}>
          {talent
            ? talents?.find((item) => {
                return item.id === talentId
              })?.name
            : talentId}
        </span>
      )
    })
  }
  const fetchApiContestDetail = useCallback(
    async (id: number) => {
      try {
        setLoading(true)
        const contestDetail = await fetchContestDetail(id)
        const valueConvertToTimeLocalL: Contest = {
          ...contestDetail,
          phase_1_start_date: convertUTCToLocal(contestDetail.phase_1_start_date),
          phase_1_end_date: convertUTCToLocal(contestDetail.phase_1_end_date),
          phase_2_start_date: convertUTCToLocal(contestDetail.phase_2_start_date),
          phase_2_end_date: convertUTCToLocal(contestDetail.phase_2_end_date),
          phase_3_start_date: convertUTCToLocal(contestDetail.phase_3_start_date),
          phase_3_end_date: convertUTCToLocal(contestDetail.phase_3_end_date)
        }
        setData(valueConvertToTimeLocalL)
      } catch (error) {
        showToast('error', error as string)
        setError(new Error(error as string))
      } finally {
        setLoading(false)
      }
    },
    [setError]
  )
  useEffect(() => {
    fetchApiContestDetail(params?.id as unknown as number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])
  useLayoutEffect(() => {
    getTalents()
    getSheepnParams()
  }, [])
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <div className='flex justify-between items-center'>
            <div>
              <span className='font-bold text-2xl mb-3'>{translation('DETAIL_CONTEST')}</span>
            </div>
            <div>
              <GoBackButton />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 col-xl-7'>
              <div className='flex mb-4 gap-3'>
                <div className='label col-2 font-semibold'>{translation('NAME')} : </div>
                <div className='value flex-1 overflow-ellipsis-text' title={data?.name}>
                  {data?.name}
                </div>
              </div>
              <div className='flex mb-4 gap-3'>
                <div className='label col-2 font-semibold'>{translation('DESCRIPTION')} : </div>
                <div className='value flex-1 overflow-ellipsis-text' title={data?.description}>
                  {data?.description}
                </div>
              </div>
              <div className='flex mb-4 gap-3'>
                <div className='label col-2 font-semibold'>{translation('SHEEP_PARAM')} : </div>
                <div className='value flex-1'>
                  {sheepParams !== undefined ? getLabelSheepParams(data?.sheepn_param_ids as any, sheepParams) : ''}
                  {/* <div className='mt-0'></div> */}
                </div>
              </div>
              <div className='flex mb-4 gap-3'>
                <div className='label col-2 font-semibold'>{translation('TALENT')} : </div>
                <div className='value flex-1'>
                  {talents !== undefined ? getLabelTalent(data?.talent_ids as any, talents) : ''}
                  {/* <div className='mt-0'></div>{' '} */}
                </div>
              </div>
            </div>
            <div className='col-12 col-xl-5'>
              <div className='flex mb-4 gap-3'>
                <div className='label col-4 font-semibold'>{translation('BONUS_TALENT')} : </div>
                <div className='value flex-1'>{data?.bonus_talent}</div>
              </div>
              <div className='flex mb-4 gap-3'>
                <div className='label col-4 font-semibold'>{translation('BONUS_PARAMETER')} : </div>
                <div className='value flex-1'>{data?.bonus_parameter}</div>
              </div>
              <div className='flex mb-4 gap-3'>
                <div className='label col-4 font-semibold'>{translation('MAXIMUM_INCENTIVE_BONUS')} : </div>
                <div className='value flex-1'>{data?.maximum_incentive_bonus}</div>
              </div>
              <div className='flex mb-4 gap-3'>
                <div className='label col-4 font-semibold'>{translation('MAXIMUM_INCENTIVE_STAR')} : </div>
                <div className='value flex-1'>{data?.maximum_incentive_star}</div>
              </div>
              <div className='flex mb-4 gap-3'>
                <div className='label col-4 font-semibold'>{translation('REMAIN_TIME')} : </div>
                <div className='value flex-1'>{data?.remain_time}</div>
              </div>
            </div>
          </div>

          <div className='flex flex-row border'>
            <div className='flex flex-col w-1/3 items-center p-3 gap-3 border-r'>
              <BaseDatePicker
                label={translation('PHASE_1_START_DATE')}
                minDate={dayjs().toDate()}
                selectedDate={new Date(dayjs(data?.phase_1_start_date).format(DAYJS_DATE_FORMAT))}
                onChangeSelectedDate={() => {}}
                disabled
              />
              <BaseDatePicker
                label={translation('PHASE_1_END_DATE')}
                minDate={dayjs().toDate()}
                selectedDate={new Date(dayjs(data?.phase_1_end_date).format(DAYJS_DATE_FORMAT))}
                onChangeSelectedDate={() => {}}
                disabled
              />
            </div>
            <div className='flex flex-col w-1/3 items-center p-3 gap-3'>
              <BaseDatePicker
                label={translation('PHASE_2_START_DATE')}
                minDate={dayjs().toDate()}
                selectedDate={new Date(dayjs(data?.phase_2_start_date).format(DAYJS_DATE_FORMAT))}
                onChangeSelectedDate={() => {}}
                disabled
              />
              <BaseDatePicker
                label={translation('PHASE_2_END_DATE')}
                minDate={dayjs().toDate()}
                selectedDate={new Date(dayjs(data?.phase_2_end_date).format(DAYJS_DATE_FORMAT))}
                onChangeSelectedDate={() => {}}
                disabled
              />
            </div>
            <div className='flex flex-col w-1/3 items-center p-3 gap-3 border-l'>
              <BaseDatePicker
                label={translation('PHASE_3_START_DATE')}
                minDate={dayjs().toDate()}
                selectedDate={new Date(dayjs(data?.phase_3_start_date).format(DAYJS_DATE_FORMAT))}
                onChangeSelectedDate={() => {}}
                disabled
              />
              <BaseDatePicker
                label={translation('PHASE_3_END_DATE')}
                minDate={dayjs().toDate()}
                selectedDate={new Date(dayjs(data?.phase_3_end_date).format(DAYJS_DATE_FORMAT))}
                onChangeSelectedDate={() => {}}
                disabled
              />
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

export default ContestDetailPage
