import Error from '@components/Error'
import { userPaths } from '@routes/index'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
const ErrorContext = createContext<{
  error: Error | null
  setError: (error: Error | null, goBackPath?: string) => void
}>({
  error: null,
  setError: () => {}
})
interface ErrorContextProps {
  children: ReactNode
}

export const ErrorContextProvider: React.FC<ErrorContextProps> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null)
  const [goBackPath, setGoBackPath] = useState(userPaths?.contests)
  const location = useLocation()

  // Clear the error when the location (route) changes
  useEffect(() => {
    setError(null)
    setGoBackPath(userPaths?.contests)
  }, [location])

  // Provide the error context to all components
  return (
    <ErrorContext.Provider
      value={{
        error,
        setError: (error, path) => {
          setError(error)
          if (path) {
            setGoBackPath(path as string)
          }
        }
      }}
    >
      {error ? <Error errorMessage={error.message} clearError={() => setError(null)} path={goBackPath} /> : children}
    </ErrorContext.Provider>
  )
}

export const useErrorContext = () => {
  return useContext(ErrorContext)
}
