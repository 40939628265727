import Contests from './contests'
import Common from './common'
import Rewards from './rewards'
import Notification from './notification'
import News from './news'
export const EN: {
  [key: string]: any
} = {
  ...Common.en,
  Contests: Contests.en,
  Rewards: Rewards.en,
  Notification: Notification.en,
  News: News.en
}
