import BigNumber from 'bignumber.js'
import * as z from 'zod'

const ErrorMessageKeys = {
  NUMBER_MAX: 'NUMBER_MAX',
  ARRAY_MAX: 'ARRAY_MAX',
  NUMBER_INTEGERS_TYPE: 'NUMBER_INTEGERS_TYPE',
  NUMBER_INVALID_TYPE: 'NUMBER_INVALID_TYPE',
  NUMBER_MIN: 'NUMBER_MIN',
  NUMBER_INCORRECT_FORMAT_DECIMAL: 'NUMBER_INCORRECT_FORMAT_DECIMAL',
  REQUIRED: 'REQUIRED',
  HIGHEST_PLACE_MUST_BE_LESS_THAN_LOWEST_PLACE: 'HIGHEST_PLACE_MUST_BE_LESS_THAN_LOWEST_PLACE',
  LOWEST_PLACE_MUST_BE_GREATER_THAN_HIGHEST_PLACE: 'LOWEST_PLACE_MUST_BE_GREATER_THAN_HIGHEST_PLACE',
  DUPLICATE_RARITY_AND_TYPE: 'DUPLICATE_RARITY_AND_TYPE',
  THE_RANK_GROUP_MIN_ARRAY: 'THE_RANK_GROUP_MIN_ARRAY'
} as const

const errorMessage = (key: string, paramValue: any = ''): string => {
  return `ERROR_${key}${paramValue || paramValue === 0 ? '_' + paramValue : ''}`
}

// const message = {
//   number: {
//     integers_type_error: (filed: string) => `${filed} must be a integers`,
//     invalid_type_error: (filed: string) => `${filed} must be a number`,
//     max: (value: number, name: string) => `Please enter ${name} within ${value.toLocaleString()}`,
//     min: (value: number, name: string) => `Please enter ${name} greater than or equal to ${value.toLocaleString()}`,
//     incorrect_format_decimal_error: (fieldName: string) =>
//       `Invalid ${fieldName}. Please enter a valid number with up to 16 digits after the decimal point.`
//   },
//   array: {
//     max: (value: number, name: string) => `Maximum number of records for ${name} is ${value}`
//   }
// }
const message = {
  number: {
    integers_type_error: () => errorMessage(ErrorMessageKeys.NUMBER_INTEGERS_TYPE),
    invalid_type_error: () => errorMessage(ErrorMessageKeys.NUMBER_INVALID_TYPE),
    max: (value: number) => errorMessage(ErrorMessageKeys.NUMBER_MAX, value),
    min: (value: number) => errorMessage(ErrorMessageKeys.NUMBER_MIN, value),
    incorrect_format_decimal_error: () => errorMessage(ErrorMessageKeys.NUMBER_INCORRECT_FORMAT_DECIMAL)
  },
  array: {
    max: (value: number) => errorMessage(ErrorMessageKeys.ARRAY_MAX, value)
  }
}

const ContestRewardsEggsSchema = z.object({
  id: z.number().optional(),
  rarity_id: z.number(),
  talent_id: z.number(),
  amount: z
    .number({ invalid_type_error: message.number.integers_type_error() })
    .max(99, message.number.max(100))
    .min(1, message.number.min(1))
    .int(),
  _destroy: z.string().optional(),
  _id: z.string().optional()
})

const ContestRewardsItemsSchema = z.object({
  id: z.number().optional(),
  item_type_id: z.number(),
  amount: z
    .number({ invalid_type_error: message.number.integers_type_error() })
    .max(99, message.number.max(100))
    .min(1, message.number.min(1))
    .int(),
  _destroy: z.string().optional(),
  _id: z.string().optional()
})

const ContestRewardsSchema = z
  .object({
    id: z.string().or(z.number()).optional(),
    highest_place: z
      .number({ invalid_type_error: message.number.integers_type_error() })
      .max(99999, message.number.max(100000))
      .min(1, message.number.min(1))
      .int(),
    lowest_place: z
      .number({ invalid_type_error: message.number.integers_type_error() })
      .max(99999, message.number.max(100000))
      .min(1, message.number.min(1))
      .int()
      .nullable(),

    spt_amount: z
      .any()
      .refine(
        (value) => {
          if (value == null) return { valid: true }
          const num = new BigNumber(value)
          if (num.isNaN()) return false
          return true
        },
        { message: message.number.invalid_type_error() }
      )
      .refine(
        (value) => {
          const num = new BigNumber(value)
          if (num.comparedTo(new BigNumber(10000)) === -1) {
            return true
          } else {
            return false
          }
        },
        { message: message.number.max(10000) }
      )
      .refine(
        (value) => {
          const num = new BigNumber(value)
          if (num.comparedTo(new BigNumber(0)) === -1) {
            return false
          } else {
            return true
          }
        },
        { message: message.number.min(0) }
      )
      .refine(
        (value) => {
          if (value == null) return true // Allow null or undefined
          const stringValue = new BigNumber(value).toFixed() // Convert to string with full precision
          const decimalIndex = stringValue.indexOf('.')
          if (decimalIndex === -1) return true // No decimal places, valid
          // Count decimal places
          const decimalPlaces = stringValue.length - decimalIndex - 1
          return decimalPlaces <= 18 // Check if decimal places don't exceed 18
        },
        { message: message.number.incorrect_format_decimal_error() }
      ),

    gspt_amount: z
      .any()
      .refine(
        (value) => {
          if (value == null) return { valid: true }
          const num = new BigNumber(value)
          if (num.isNaN()) return false
          return true
        },
        { message: message.number.invalid_type_error() }
      )
      .refine(
        (value) => {
          const num = new BigNumber(value)
          if (num.comparedTo(new BigNumber(10000)) === -1) {
            return true
          } else {
            return false
          }
        },
        { message: message.number.max(10000) }
      )
      .refine(
        (value) => {
          const num = new BigNumber(value)
          if (num.comparedTo(new BigNumber(0)) === -1) {
            return false
          } else {
            return true
          }
        },
        { message: message.number.min(0) }
      )
      .refine(
        (value) => {
          if (value == null) return true // Allow null or undefined
          const stringValue = new BigNumber(value).toFixed() // Convert to string with full precision
          const decimalIndex = stringValue.indexOf('.')
          if (decimalIndex === -1) return true // No decimal places, valid
          // Count decimal places
          const decimalPlaces = stringValue.length - decimalIndex - 1
          return decimalPlaces <= 18 // Check if decimal places don't exceed 18
        },
        { message: message.number.incorrect_format_decimal_error() }
      ),
    contest_rewards_eggs: z.array(ContestRewardsEggsSchema).max(35, message.array.max(35)),
    // contest_rewards_items: z.array(ContestRewardsItemsSchema).max(12, message.array.max(12, 'items')),
    _destroy: z.string().optional(),
    _id: z.string().optional()
  })
  .superRefine((data, ctx) => {
    if (!data._destroy) {
      if (data.lowest_place !== null && data.highest_place > data.lowest_place) {
        //'Highest place must be less than lowest place.'
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errorMessage(ErrorMessageKeys.HIGHEST_PLACE_MUST_BE_LESS_THAN_LOWEST_PLACE),
          path: ['highest_place']
        })
        //'Lowest place must be greater than highest place.'
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: errorMessage(ErrorMessageKeys.LOWEST_PLACE_MUST_BE_GREATER_THAN_HIGHEST_PLACE),
          path: ['lowest_place']
        })
      }
      const uniqueEggs: Set<string> = new Set()
      // const uniqueItems: Set<string> = new Set()

      if (data.contest_rewards_eggs.length >= 2) {
        const contest_rewards_eggs = data.contest_rewards_eggs.filter((item) => {
          return !item._destroy
        })
        //`You can't reward an egg with the same rarity and type twice`
        contest_rewards_eggs.forEach((egg, index) => {
          const eggKey = `${egg.rarity_id}-${egg.talent_id}`
          if (uniqueEggs.has(eggKey)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: errorMessage(ErrorMessageKeys.DUPLICATE_RARITY_AND_TYPE),
              path: ['contest_rewards_eggs']
            })
          } else {
            uniqueEggs.add(eggKey)
          }
        })
      }
      // if (data.contest_rewards_items.length >= 2) {
      //   const contest_rewards_items = data.contest_rewards_items.filter((item) => {
      //     return !item._destroy
      //   })
      //   contest_rewards_items.forEach((item, index) => {
      //     const itemKey = `${item.item_type_id}`
      //     if (uniqueItems.has(itemKey)) {
      //       ctx.addIssue({
      //         code: z.ZodIssueCode.custom,
      //         message: `You can't reward an item with the same properties twice`,
      //         path: ['contest_rewards_items']
      //       })
      //     } else {
      //       uniqueItems.add(itemKey)
      //     }
      //   })
      // }
    }
  })

export function hasOverlappingRanges(ranges: [number, number][]): boolean {
  // Biến đổi danh sách các khoảng thành một danh sách các sự kiện ('start' hoặc 'end')
  const events: { time: number; type: 'start' | 'end' }[] = []
  ranges.forEach((range) => {
    events.push({ time: range[0], type: 'start' })
    events.push({ time: range[1], type: 'end' })
  })

  // Sắp xếp các sự kiện theo thời gian, nếu có thời gian giống nhau, 'end' trước 'start'
  events.sort((a, b) => (a.time === b.time ? (a.type === 'end' ? -1 : 1) : a.time - b.time))

  let activeRanges = 0 // Bộ đếm cho số lượng khoảng đang hoạt động

  // Quét qua danh sách sự kiện
  for (const event of events) {
    if (event.type === 'start') {
      activeRanges++ // Một khoảng mới bắt đầu
      if (activeRanges > 1) {
        return true // Phát hiện vi phạm
      }
    } else {
      activeRanges-- // Một khoảng kết thúc
    }
  }

  return false // Không có vi phạm
}

const RankGroupSchema = z.object({
  id: z.string().or(z.number()).optional(),
  name: z.string(),
  contest_rewards: z.array(ContestRewardsSchema).superRefine((data, ctx) => {
    if (
      !(
        data.filter((item) => {
          return !!!item._destroy
        }).length > 0
      )
    ) {
      //'The rank group must have at least 1 contest reward. Please add 1 contest reward to the rank group'
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage(ErrorMessageKeys.THE_RANK_GROUP_MIN_ARRAY, 1)
      })
    }
  })
})

export const RootSchema = z.object({
  rank_groups: z.array(RankGroupSchema)
})

type RankGroup = z.TypeOf<typeof RankGroupSchema>
type ContestRewards = z.TypeOf<typeof ContestRewardsSchema>

export { ContestRewardsEggsSchema, ContestRewardsItemsSchema, ContestRewardsSchema, RankGroupSchema }
export type { RankGroup, ContestRewards }
