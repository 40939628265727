import axios from "@libs/axios";
import { TalentList, TalentListParams } from "./types/Talent";

const prefix = "/admin";
export const fetchTalents = async (params?: TalentListParams) => {
  const { data } = await axios.get<TalentList>(`${prefix}/talents`, {
    params,
  });
  return data.data;
};
