import InputNumber from '@components/Form/InputNumber'
import { Form } from 'react-bootstrap'
import Eggs from '../Eggs'
// import Items from '../Items'
import { useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import BigNumber from '@libs/bignumber'
import Input from '@components/Form/Input'
import { useRewardContext } from '@pages/Rewards/context'

type Props = {
  index: number
  name: string
}

const FormReward = ({ index, name }: Props) => {
  const {
    register,
    watch,
    trigger,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext()

  const highestPlace = watch(`${name}.[${index}].highest_place`)

  const lowestPlace = watch(`${name}.[${index}].lowest_place`)
  const triggerError = () => {
    trigger(name).then(() => {
      const values: any[] = getValues(name)
      values.forEach((item, index) => {
        if (item?._destroy) {
          clearErrors(`${name}[${index}]`)
        }
      })
    })
  }

  const { translation } = useRewardContext()

  useEffect(() => {
    triggerError()
    // eslint-disable-next-line
  }, [highestPlace, lowestPlace])

  return (
    <div className='mb-3'>
      <div className='row mb-3'>
        <div className='col-12 col-xl-6'>
          <Form.Group className='mb-3 w-full'>
            <InputNumber
              label={translation('HIGHEST_PLACE')}
              register={register(`${name}.[${index}].highest_place`, { valueAsNumber: true, required: true })}
              errors={errors}
              t={(key: string) =>
                translation(key, {
                  name: translation('HIGHEST_PLACE'),
                  nameToLowCase: translation('HIGHEST_PLACE').toLocaleLowerCase()
                })
              }
            />
          </Form.Group>
        </div>
        <div className='col-12 col-xl-6'>
          <Form.Group className='mb-3 w-full'>
            <InputNumber
              label={translation('LOWEST_PLACE')}
              register={register(`${name}.[${index}].lowest_place`, {
                setValueAs: (value) => {
                  return value === '' || value === null ? null : Number(value)
                }
              })}
              t={(key: string) =>
                translation(key, {
                  name: translation('LOWEST_PLACE'),
                  nameToLowCase: translation('LOWEST_PLACE').toLocaleLowerCase()
                })
              }
              errors={errors}
            />
          </Form.Group>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12 col-xl-6'>
          <Form.Group controlId={`${name}.[${index}].spt_amount`} className='mb-3 w-full'>
            <Input
              label={translation('SPT_LABEL')}
              register={register(`${name}.[${index}].spt_amount`, {
                setValueAs: (value) => {
                  return value === '' || value === null ? 0 : new BigNumber(value).toFixed()
                }
              })}
              t={(key: string) =>
                translation(key, {
                  name: translation('SPT_LABEL'),
                  nameToLowCase: translation('SPT_LABEL').toLocaleLowerCase()
                })
              }
              errors={errors}
            />
          </Form.Group>
        </div>
        <div className='col-12 col-xl-6'>
          <Form.Group controlId={`${name}.[${index}].gspt_amount`} className='mb-3 w-full'>
            <Input
              label={translation('GSPT_LABEL')}
              register={register(`${name}.[${index}].gspt_amount`, {
                setValueAs: (value) => {
                  return value === '' || value === null ? 0 : new BigNumber(value).toFixed()
                }
              })}
              t={(key: string) =>
                translation(key, {
                  name: translation('GSPT_LABEL'),
                  nameToLowCase: translation('GSPT_LABEL').toLocaleLowerCase()
                })
              }
              errors={errors}
            />
          </Form.Group>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-xl-6'>
          <Form.Group controlId={`${name}.[${index}].eggs`} className='mb-3 w-full'>
            <Form.Label className='text-base'>Eggs</Form.Label>
            <Eggs
              index={index}
              name={`${name}.[${index}].contest_rewards_eggs`}
              trigger={() => {
                triggerError()
              }}
            />
            <ErrorMessage
              errors={errors}
              name={`${name}.[${index}].contest_rewards_eggs`}
              render={({ message }) => (
                <Form.Text className='text-danger mt-2 block'>
                  {message
                    ? translation(message, {
                        name: translation('EGGS'),
                        nameToLowCase: translation('EGGS').toLocaleLowerCase()
                      })
                    : ''}
                </Form.Text>
              )}
            />
          </Form.Group>
        </div>
        <div className='col-12 col-xl-6'>
          {/* <Form.Group controlId={`${name}.[${index}].items`} className='mb-3 w-full'>
            <Form.Label className='text-base'>Items</Form.Label>
            <Items
              index={index}
              name={`${name}.[${index}].contest_rewards_items`}
              trigger={() => {
                  triggerError()
              }}
            />
            <ErrorMessage
              errors={errors}
              name={`${name}.[${index}].contest_rewards_items`}
              render={({ message }) => <Form.Text className='text-danger mt-2 block'>{translation(message)}</Form.Text>}

            />
          </Form.Group> */}
        </div>
      </div>
    </div>
  )
}

export default FormReward
