import classNames from 'classnames'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Plus } from 'react-bootstrap-icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
interface UploadImageProps {
  setValue: (value: any) => void
  image?: string
  className?: string
}
export const UploadFileStyled = styled.div`
  .container-upload {
    border: 1px dashed #6c757d;
    .container-icon {
      width: 100%;
      .icon {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-radius: 100%;
        margin: 0 auto;
      }
    }
  }
`
const ContainerImage = styled.div`
  background: #ffffff;
  width: 100%;
  max-width: 100%;
  aspect-ratio: 5/2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    transition: filter 0.3s ease-in-out; /* Smooth transition for the blur effect */
  }

  .container-upload {
    opacity: 0;
    transition: all ease-in-out 0.3s;

    .icon {
      color: #6c757d;
      background-color: transparent;
      transition: all 0.3s ease;
    }
  }

  .container-text .text-main {
    color: #ffffff;
    font-size: 16;
    font-weight: 500;
    transition: all 0.3s ease;
  }

  &:hover {
    img {
      filter: blur(4px); /* Apply blur effect */
    }

    .icon {
      color: #ffffff;
    }

    .container-upload {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay */
    }
  }
`
const UploadImage = forwardRef(({ image, setValue, className }: UploadImageProps, ref) => {
  const { t } = useTranslation()
  const inputUploadRef = useRef<HTMLInputElement>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const handleBase64Change = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      try {
        const allowedExtensions = ['jpeg', 'png', 'jpg']
        const fileExtension = file.name.split('.').pop()?.toLowerCase()

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            setImageUrl(reader.result as string)
            setValue(file as any)
          }
        } else {
          setImageUrl(null)
          setValue(null)
        }
      } catch (error) {
        setValue(null)
        setImageUrl(null)
      }
    }
  }
  useEffect(() => {
    if (image) {
      setImageUrl(image)
    } else {
      setImageUrl(null)
    }
  }, [image])

  return (
    <UploadFileStyled className={classNames(['w-40 rounded overflow-hidden', className])}>
      {imageUrl ? (
        <ContainerImage>
          <img src={imageUrl as string} alt='img-avatar' />
          <button
            type='button'
            className='container-upload w-full h-full rounded absolute bg-transparent top-0 left-0'
            onClick={() => {
              inputUploadRef.current?.click()
            }}
          >
            <div className='container-icon'>
              <span className='icon'>
                <Plus size={25} />
              </span>
            </div>
            <div className='container-text'>
              <div className='text-main'>Click to upload image</div>
            </div>
          </button>
        </ContainerImage>
      ) : (
        <>
          <button
            type='button'
            className='container-upload w-full h-full rounded'
            onClick={() => {
              inputUploadRef.current?.click()
            }}
          >
            <div className='container-icon'>
              <span className='icon'>
                <Plus size={25} />
              </span>
            </div>

            <div className='container-text'>
              <div className='text-main'>{t('UPLOAD_IMAGE')}</div>
            </div>
          </button>
        </>
      )}
      <input
        ref={inputUploadRef}
        type='file'
        accept='image/*'
        onChange={handleBase64Change}
        className='upload-input'
        style={{ display: 'none' }}
      />
    </UploadFileStyled>
  )
})

export default UploadImage
