import React, { useEffect, useState } from 'react'
import GoBackButton from '../components/GoBack'
import { Accordion, Form } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { RankGroup, RootSchema } from '../schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useLoading } from '@layouts/Loading'
import { useErrorContext } from '@context/Error'
// import { useNotification } from '@context/Notification'
import { useParams } from 'react-router-dom'
import { getRewardsById } from '@store/rewards'
import ClassReward from './components/Class'
import { RewardContextProvider } from '../context'
import { userPaths } from '@routes/index'
import { parseNumbersToDecimal } from '@utils/parseNumbers'
import { useTranslation } from 'react-i18next'

// type Props = {}

const DetailReward = () => {
  const { t } = useTranslation()
  const translation = (key: string) => {
    const name = 'Rewards'
    return t(`${name}.${key}`)
  }
  const [activeKey, setActiveKey] = useState<string | null>('')
  const method = useForm<{ rank_groups: RankGroup[] }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(RootSchema)
  })

  const { disposeLoading, onLoading } = useLoading()

  const errorContext = useErrorContext()

  const { watch, setValue } = method

  //   const { showNotification } = useNotification()

  const handleAccordionSelect = (key: string | null) => {
    setActiveKey(key === activeKey ? null : key)
    setTimeout(() => {
      // Scroll to the Accordion Header corresponding to the selected key
      const headerElement = document.getElementById(`accordion-header-${key}`)
      if (headerElement) {
        headerElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }, 500) // Delay of 0.5 seconds (500 milliseconds)
  }

  const params = useParams()

  const fetchDataRewards = async (id: number) => {
    try {
      onLoading()
      const rs = await getRewardsById(id)
      let keysToParse: string[] = ['gspt_amount', 'spt_amount']
      let parsedData: RankGroup[] = (rs?.rank_groups as RankGroup[]).map((item) => {
        return {
          ...item,
          contest_rewards: item.contest_rewards.map((reward) => {
            return parseNumbersToDecimal(reward, keysToParse)
          })
        }
      })
      setValue('rank_groups', parsedData)
    } catch (error: any) {
      setValue('rank_groups', [])
      if (error?.errors?.code === 404) {
        errorContext.setError(new Error('Rewards configuration not found'), userPaths?.contests)
      } else {
        errorContext.setError(new Error(error?.message ?? 'ERROR UNKNOWN'), userPaths?.contests)
      }
    } finally {
      disposeLoading()
    }
  }

  const values = watch(['rank_groups'])?.[0]

  // const listKey = useMemo(() => {
  //   return values ? values?.map((item) => item.id) : []
  // }, [values])

  useEffect(() => {
    fetchDataRewards(params?.id as unknown as number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  return (
    <RewardContextProvider>
      <div className='w-full h-fit mb-4'>
        <div className='header flex justify-between mb-2'>
          <h1 className='title font-bold text-2xl'>{translation('REWARDS_CONFIGURATION')}</h1>
          <GoBackButton />
        </div>
        <Accordion
          alwaysOpen
          onSelect={(e) => {
            handleAccordionSelect(e?.[0] as string)
          }}
          activeKey={activeKey}
        >
          <FormProvider {...method}>
            <Form>
              {values ? (
                <>
                  {values.map((item, index) => {
                    return (
                      <React.Fragment key={item?.id}>
                        <Accordion.Item eventKey={item?.id as string}>
                          <Accordion.Header id={`accordion-header-${item?.id}`}>RG - {item.name}</Accordion.Header>
                          <Accordion.Body>
                            <ClassReward index={index} />
                          </Accordion.Body>
                        </Accordion.Item>
                      </React.Fragment>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </Form>
          </FormProvider>
        </Accordion>
      </div>
    </RewardContextProvider>
  )
}

export default DetailReward
