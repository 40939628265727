import BaseSelect from '@atoms/Select'
import { OptionCategory, OptionStatus } from '@pages/Notification/constants'
import { useComponentContext } from '@pages/Notification/context'
import { debounce } from 'lodash'
import React, { useCallback } from 'react'
import { Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

type SearchProps = {
  onSearch: (value: SearchParam) => void
}

export interface SearchParam {
  category?: number
  status?: 0 | 1
  title?: string
}

const FormSearch = ({ onSearch }: SearchProps) => {
  const { control, watch, register, setValue } = useForm<SearchParam>({
    mode: 'onTouched',
    reValidateMode: 'onChange'
  })
  const watchedFields = watch()
  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(onSearch, 300), [])
  const addAllOption = (option: any) => {
    return [
      {
        label: 'ALL',
        value: undefined
        
      },
      ...option
    ]
  }
  const { translation } = useComponentContext()
  const warpTranslationOption = useCallback(
    (option: { value: string | number; label: string }[]) => {
      return option.map((item) => {
        return { ...item, label: translation(item.label) }
      })
    },
    [translation]
  )
  return (
    <Form className='d-flex gap-3 w-full'>
      <Form.Group controlId='form-name' className='min-w-[250px]'>
        <Form.Label className='font-bold text-sm capitalize'>{translation('TITLE.JA')}</Form.Label>
        <Form.Control
          type='text'
          placeholder={translation('ENTER_TO_SEARCH')}
          {...(register('title'),
          {
            onChange: (e) => {
              debouncedSearch({ ...watchedFields, title: e.target.value !== '' ? e.target.value : undefined })
              setValue('title', e.target.value !== '' ? e.target.value : undefined)
            }
          })}
        />
      </Form.Group>
      <Form.Group controlId='platformsOption' className='min-w-[150px]'>
        <Form.Label className='font-bold text-sm capitalize'>{translation('CATEGORY')}</Form.Label>
        <Controller
          name={'category'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <BaseSelect
                placeholder={translation('SELECT_TO_SEARCH', {
                  nameToLowCase: translation('CATEGORY').toLocaleLowerCase()
                })}
                options={warpTranslationOption(addAllOption(OptionCategory))}
                value={value}
                onChange={(e) => {
                  debouncedSearch({
                    ...watchedFields,
                    category: e.value !== undefined ? e.value : undefined
                  })
                  onChange(e.value)
                }}
              />
            )
          }}
        />
      </Form.Group>
      <Form.Group controlId='platformsOption' className='min-w-[150px]'>
        <Form.Label className='font-bold text-sm capitalize'>{translation('STATUS')}</Form.Label>
        <Controller
          name={'status'}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <BaseSelect
                placeholder={'Select a status to search'}
                options={warpTranslationOption(addAllOption(OptionStatus))}
                value={value}
                onChange={(e) => {
                  debouncedSearch({ ...watchedFields, status: e.value !== undefined ? e.value : undefined })
                  onChange(e.value)
                }}
              />
            )
          }}
        />
      </Form.Group>
    </Form>
  )
}

export default FormSearch
