import BaseSelect from '@atoms/Select'
import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import { Form } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
type Props = {
  label?: string
  placeholder?: string
  showLabel?: boolean
  control?: any
  name?: string
  options?: {
    value: string | number | boolean
    label: string
  }[]
  errors?: { [x: string]: any }
  showError?: boolean
  callbackOnChange?: () => void
  disabled?: boolean
}

const Select = ({
  label = '',
  placeholder,
  showLabel = true,
  control,
  name,
  options,
  showError = true,
  errors,
  callbackOnChange,
  disabled = false
}: Props) => {
  return (
    <Form.Group controlId={`form-${label.toLowerCase()}`} className='w-full'>
      {showLabel ? <Form.Label className='text-base capitalize'>{label}</Form.Label> : ''}
      <Controller
        name={name as string}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <BaseSelect
              placeholder={placeholder}
              options={options}
              value={value}
              disabled={disabled}
              onChange={(e) => {
                onChange(e.value)
                if (callbackOnChange) {
                  callbackOnChange()
                }
              }}
            />
          )
        }}
      />
      {showError ? (
        <ErrorMessage
          errors={errors}
          name={name ?? ''}
          render={({ message }) => <Form.Text className='text-danger mt-2 block'>{message}</Form.Text>}
        />
      ) : (
        <></>
      )}
    </Form.Group>
  )
}

export default Select
