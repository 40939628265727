export const authPaths: { [key: string]: string } = {
  login: '/login'
}
export const authPathsArr = Object.keys(authPaths).map((key) => authPaths[key])
export const userPaths: { [key: string]: string } = {
  home: '/home',
  admin: '/admin',
  users: '/users',
  roles: '/roles',
  items: '/items',
  contests: '/contests',
  rewards: '/rewards',
  news: '/news',
  appVersion: '/app-version',
  notification: '/notification'
}
export const userPathsArr = Object.keys(userPaths).map((key) => userPaths[key])

export const testPaths: { [key: string]: string } = {
  web3Auth: '/web3Auth'
}
export const testPathsArr = Object.keys(testPaths).map((key) => testPaths[key])
