/* eslint-disable */
import { userPaths } from '@routes/index'
import React, { useEffect, useRef, useState } from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Search, { SearchParam } from './components/Search'
import BaseTable, { TableRef, Header } from '@atoms/Table'
import Pagination from '@atoms/Pagination'
import { BsPencilSquare } from 'react-icons/bs'
import { TrashFill } from 'react-bootstrap-icons'
import ConfirmDialog, { ConfirmDialogRef } from '@components/ConfirmDialog'
import { AiFillDelete } from 'react-icons/ai'
import { IListNotification, IListNotificationParams, INotification } from '@store/types/Notification'
import { EnumCategory, EnumCategoryColor, OptionStatus } from './constants'
import { convertUTCToLocal } from '@utils/convertTimezone'
import { DAYJS_DATE_FORMAT } from '@constants/index'
import { deleteNotificationById, getListNotification } from '@store/notification'
import _ from 'lodash'
import { useNotification } from '@context/Notification'
import { ContextComponentProvider, useComponentContext } from './context'
import { useTranslation } from 'react-i18next'

type Props = {}

const Notification = (props: Props) => {
  const baseTableRef = useRef<TableRef>(null)
  const confirmRef = useRef<ConfirmDialogRef>(null)
  const { showNotification } = useNotification()
  const { translation } = useComponentContext()

  const headers: Header[] = [
    {
      label: translation('TITLE.JA'),
      key: 'Title', //todo
      render: (value: INotification) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={value.title.ja}
          style={{ width: '100%', maxWidth: 300 }}
        >
          {value.title.ja}
        </div>
        
      )
    },    
    {
      label: translation('TITLE.EN'),
      key: 'Title', //todo
      render: (value: INotification) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={value.title.en}
          style={{ width: '100%', maxWidth: 300 }}
        >
          {value.title.en}
        </div>
        
      )
    },
    {
      label: translation('CATEGORY'),
      key: 'category',
      width: '10',
      render: (value: INotification) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={EnumCategory[value.category]}
          style={{ width: '100%', maxWidth: 500 }}
        >
          <span
            className={`text-white text-sm font-medium me-2 px-2.5 py-0.5 rounded`}
            style={{ backgroundColor: EnumCategoryColor[value.category] }}
          >
            {translation(EnumCategory[value.category])}
          </span>
        </div>
      )
    },
    {
      label: translation('DESCRIPTION.JA'),
      key: 'description', //todo
      render: (value: INotification) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={value.description.ja}
          style={{ width: '100%', maxWidth: 500 }}
        >
          {value.description.ja}
        </div>
      )
    },
    {
      label: translation('DESCRIPTION.EN'),
      key: 'description', //todo
      render: (value: INotification) => (
        <div
          className='text-ellipsis overflow-hidden whitespace-nowrap'
          title={value.description.en}
          style={{ width: '100%', maxWidth: 500 }}
        >
          {value.description.en}
        </div>
      )
    },
    {
      label: translation('START_ANNOUNCEMENTED_AT'),
      key: 'start_announcemented_at',
      render: (value: INotification) => convertUTCToLocal(value.start_announcemented_at, DAYJS_DATE_FORMAT),
      align: 'center'
    },
    {
      label: translation('END_ANNOUNCEMENTED_AT'),
      key: 'end_announcemented_at',
      align: 'center',
      render: (value: INotification) => convertUTCToLocal(value.end_announcemented_at, DAYJS_DATE_FORMAT)
    },
    {
      label: translation('STATUS'),
      key: 'status',
      align: 'center',
      render: (value: INotification) => (
        <div className='font-medium' style={{ minWidth: '100px' }}>
          {translation(OptionStatus.find((item) => item.value === value.status)?.label as string)}
        </div>
      )
    },
    {
      label: translation('ACTIONS'),
      key: 'action_key',
      width: '10',
      render: (value: INotification) => (
        <div className='flex flex-row gap-2'>
          <Button onClick={() => navigate(`${userPaths?.notification}/edit/${value.id}`)}>
            <BsPencilSquare />
          </Button>
          <Button
            variant='danger'
            onClick={() => {
              confirmRef.current?.setOpen(true)
              if (confirmRef.current?.onOk) {
                confirmRef.current.onOk(() => {
                  onDelete(value.id as number)
                })
              }
            }}
          >
            <TrashFill />
          </Button>
        </div>
      )
    }
  ]
  const [data, setData] = useState<IListNotification | null>(null)
  const [params, setParams] = useState<IListNotificationParams>({
    page: 1,
    page_size: 10
  })
  const navigate = useNavigate()
  const { t } = useTranslation()
  const fetchListNotification = async () => {
    try {
      baseTableRef.current?.setLoading(true)
      const { data } = await getListNotification(params)
      setData({
        ...data
      })
    } catch (error: any) {
      setData(null)
    } finally {
      baseTableRef.current?.setLoading(false)
    }
  }
  const onDelete = async (id: string | number) => {
    try {
      confirmRef.current?.setLoading(true)
      confirmRef.current?.setOpen(false)
      await deleteNotificationById(id)
      showNotification({
        message: translation('DELETE_NOTIFICATION_SUCCESSFULLY'),
        type: 'success',
        onOk() {
          fetchListNotification()
        }
      })
    } catch (error: any) {
      showNotification({
        message: `${(error?.message as string) ?? 'Error Unknown'}`,
        type: 'error',
        onOk() {
          confirmRef.current?.setLoading(false)
        }
      })
    } finally {
      confirmRef.current?.setLoading(false)
    }
  }
  const onSearch = (value: SearchParam) => {
    setParams({ ...params, ...value, page: 1 })
  }
  const onPageChange = (value: string | number) => {
    setParams({ ...params, page: parseInt(value as string) })
  }
  useEffect(() => {
    fetchListNotification()
  }, [params.page, params.category, params.status, params.title])
  return (
    <div className='w-full h-full flex flex-col gap-3'>
      <div>
        <div>
          <span className='font-bold text-2xl'>{translation('NOTIFICATION_MANAGEMENT')}</span>
        </div>
        <div className='flex flex-row justify-between items-center mt-3 gap-3'>
          <div className='flex-1'>
            <Search onSearch={onSearch} />
          </div>

          <Button
            className='capitalize mt-4 whitespace-nowrap'
            variant='primary'
            onClick={() => navigate(`${userPaths?.notification}/create`)}
          >
            {translation('CREATE_NOTIFICATION')}
          </Button>
        </div>
      </div>
      <div className='flex-1 h-[50%]'>
        <BaseTable headers={headers} itemsRow={data?.result ?? []} ref={baseTableRef} />
      </div>
      <div className='flex justify-center'>
        <Pagination
          onPageChange={onPageChange}
          onChangePageSize={() => null}
          totalCount={data?.total || 0}
          currentPage={params?.page ?? 0}
          siblingCount={1}
          pageSize={params?.page_size ?? 0}
        />
      </div>
      <ConfirmDialog ref={confirmRef} cancelText={t('NO_CANCEL')} okText={t('YES_I_AM_SURE')}>
        <div className='flex flex-col items-center gap-2'>
          <AiFillDelete className='text-[4rem] text-gray-400' />
          <p className='mb-4'>{t('CONFIRM_DELETE')}</p>
        </div>
      </ConfirmDialog>
    </div>
  )
}
const wrapContext = () => {
  return (
    <ContextComponentProvider>
      <Notification />
    </ContextComponentProvider>
  )
}

export default wrapContext
